<template>

  <div class="content-block dx-card responsive-paddings">
    <!-- <h2>Decommission Site</h2> -->
    
    <h6>Device SerialNum : </h6>
    <DxTextBox v-model:value="oldDevice"/>
    
    <h6>Container Name : </h6>
    <DxTextBox v-model:value="oldContainer"/>

    <DxButton class="btn" text="Decommission Site" @click="actionSite()"/>

  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxTextArea
        :height="90"
        :read-only="true"
        v-model:value="newResponse"
      />
  </div>
</template>

<script>

import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from 'devextreme-vue/button';
import DxTextArea from 'devextreme-vue/text-area';

export default {
  components: {
    DxTextBox,
    DxButton,
    DxTextArea,
  },
  data() {
    return {
      oldContainer: "",
      oldDevice: "",
      result: null,
      newResponse: null,
    };
  },
  created() {
    this.loadPageData();
  },
  methods: {
    async actionSite(){
      const site= {
        containerName: this.oldContainer,
        deviceSerial: this.oldDevice,
      }
      await this.$store.dispatch('utils/decommissionSite', site);
      this.$emit('recordUpdated');
      this.result = await this.$store.getters["utils/getDecommissionSiteResults"];
      if (this.result.recordsAffected==-1)
      {
        this.newResponse = "Site decommissioned. " + Date().toLocaleString() + "\n" + this.result.message;
      }
      else
      {
        this.newResponse = "Error. " + Date().toLocaleString() + "\n" + this.result.message;
      }
    },
    async loadPageData(refresh = false) {
      try {
        // do something
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.tb{
  margin: 10px 0 0 2px;
}
.btn{
  margin: 50px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}
</style>


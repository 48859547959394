<template>
  <div class="content-block dx-card responsive-paddings">
    <h6>Container Name : </h6>
    <DxTextBox  v-model:value="newContainer"/>

    <h6>Device SerialNum : </h6>
    <DxTextBox  v-model:value="newDevice"/>

    <h6>Operator : </h6>
    <DxSelectBox :data-source="operators" value-expr="operatorName" display-expr="operatorName" v-model:value="newOper" @value-changed="operatorChanged"/>

    <h6>Area : </h6>
    <DxCheckBox  
      text="View All"
      :value="allAreas"
      :enable-three-state-behavior="false"
      @value-changed="allAreasChanged"
    />
    <DxSelectBox v-if="allAreas==true" :data-source="areas" value-expr="areaName" display-expr="areaName" v-model:value="newArea"/>
    <DxSelectBox v-if="allAreas==false" :data-source="existingOperatorAreas" value-expr="areaName" display-expr="areaName" v-model:value="newArea"/>

    <DxDataGrid
      :data-source="newProperties"
      :show-borders="true"
      
    >
    <!-- key-expr="ID" -->
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        :select-text-on-edit-start="true"
        :start-edit-action="click"
        mode="cell"
      />
      <!-- mode="batch" -->
      <DxPaging :enabled="false"/>
      
      <DxColumn
        data-field="PropertyId"
        caption="Property"
      >
        <DxLookup
          :data-source="properties"
          value-expr="PropertyId"
          display-expr="PropertyName"
        />
      </DxColumn>
      
      <DxColumn data-field="PropValue" caption="Value"/>

    </DxDataGrid>

    <!-- <DxButton class="btn" text="Create New Site" @click="createSite()"/> -->
    <DxButton class="btn" text="Create New Site" @click="setProperties()"/>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxTextArea
        :height="140"
        :read-only="true"
        v-model:value="newResponse"
      />
  </div>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from 'devextreme-vue/button';
import DxTextArea from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxSelectBox,
    DxTextBox,
    DxButton,
    DxTextArea,
    DxCheckBox,    
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
  },
  data() {
    return {
      allAreas: false,
      areas: null,
      operators: null,
      groups: null,
      operAreas: null,
      oa: null,
      perm: null,
      newContainer: "",
      newDevice: "",
      newOper: "",
      newArea: "",
      result: null,
      newResponse: null,
      properties : [
          { PropertyId: 1, PropertyName: 'Product', },
          { PropertyId: 2, PropertyName: 'Capacity', },
          { PropertyId: 3, PropertyName: 'Downhole LSD', },
          { PropertyId: 4, PropertyName: 'External Customer ID', },
          { PropertyId: 5, PropertyName: 'AX Item ID', },
        ],
      newProperties: [{PropertyId: null, PropertyName:null}],
      // newProperties: null,
    }
  },
  created() {
    this.loadPageData();
  },
  computed: {
    existingOperatorAreas(){
      return this.$store.getters["operators/getOperatorsAreas"].filter(x => x.operatorName == this.newOper);
    }
  },
  methods: {
    async createSite(){
      const newSite= {
        containerName: this.newContainer,
        deviceSerial: this.newDevice,
        operatorName: this.newOper,
        areaName: this.newArea
      }
      await this.$store.dispatch('utils/addSiteOtodata', newSite);
      this.$emit('recordUpdated');
      this.result = await this.$store.getters["utils/getAddSiteResults"];
      if (this.result.recordsAffected==-1)
      {
        this.newResponse = "Record created. " + new Date().toISOString() + "\n" + this.result.message;
      }
      else
      {
        this.newResponse = "Record not created. " + new Date().toISOString() + "\n" + this.result.message.replaceAll(';','\r\n');
      }
    },
    setProperties(){
      // propValueChanged(prop, field, data) {
      
      // for(let i = 0;i < this.newProperties.length;i++){
      //   console.log(payload[i]);
      
      //   const newRequest = {
      //     containerPropId: newProperties[i].PropertyId,
      //     containerId: payload[i].containerId,
      //     propertyId: payload[i].propertyId,
      //     propValue: String(payload[i].propValue),
      //     valueMarginal: String(payload[i].marginal),
      //     valueCritical: String(payload[i].critical),
      //     propertySort: payload[i].propertySort
      //   };
      // }

      // let changeProp = this.changedProperties.find(x=>x.containerPropId===prop.containerPropId);

      // if (changeProp==null)
      // {
      //   changeProp={
      //     containerPropId: prop.containerPropId,
      //     containerId: this.currentRecordId,
      //     // containerId: prop.containerId,
      //     propertyId: prop.propertyId,
      //     propValue: prop.propValue,
      //     marginal: prop.valueMarginal,
      //     critical: prop.valueCritical,
      //     propertySort: prop.propertySort
      //   };

      //   this.changedProperties.push(changeProp);
      // }

      // if(field=="VALUE")
      //   { changeProp.propValue = data.value; }
      // else if(field=="MARGINAL")
      //   { changeProp.marginal=data.value; }
      // else if(field=="CRITICAL")
      //   { changeProp.critical=data.value; }

      // console.log("CHANGED PROP",this.changedProperties);
      
    },
    operatorChanged(e) {
      this.newArea = null;
    },
    allAreasChanged(e) {
      this.allAreas = e.value;
      this.newArea = null;
    },
    groupFieldOptions() {
      return {
        items: this.groups,
        valueExpr: "groupId",
        displayExpr: "groupName"
      }
    },
    areaFieldOptions() {
      return {
        items: this.areas,
        valueExpr: "areaId",
        displayExpr: "areaName"
      }
    },
    operatorFieldOptions() {
      return {
        items: this.operators,
        valueExpr: "operatorId",
        displayExpr: "operatorName"
      }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("areas/loadAreas");
        await this.$store.dispatch("operators/loadOperators");
        await this.$store.dispatch("operators/loadOperatorsAreas");
        await this.$store.dispatch("groups/loadGroups");
        this.areas = this.$store.getters["areas/getAreas"];
        this.operators = this.$store.getters["operators/getOperators"];
        this.groups = this.$store.getters["groups/getGroups"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.fld{
  margin: 20px 0 0 2px;
}
.btn{
  margin: 50px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}
</style>


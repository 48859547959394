<template>
  <!-- unicode U+276F : ❯ -->
  <h2 class="content-block">Reporting ❯ Tank History</h2>

  <DxToolbar class="menubar">
    <DxToolbarItem :options="cancelButtonOptions" location="before" widget="dxButton"/>
    <!-- <DxToolbarItem location="after" template="filterStatusCriticalTemplate"/> -->
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <!-- <h2>{{containers[0].containerName}}</h2> -->
    <h2>{{containerName}}</h2>
    <!-- <div class="pval">Downhole LSD: {{this.getPropertyVal('Downhole LSD')}}</div> -->
    <div class="pval">Downhole LSD: {{ containerDownhole }}</div>
  </div>
  
  <div class="content-block dx-card responsive-paddings">
    <div>
      <DxSelectBox 
        :data-source="historyTypes" 
        display-expr="name"
        value-expr="id" 
        :value="currentHistoryType" 
        @value-changed="updateHistType"/>
    </div>

    <DxChart :data-source="containerVolumeHistory">
      <DxZoomAndPan argument-axis="both" value-axis="none"/>
      <DxCommonSeriesSettings
        argument-field="historyDate"
        value-field="propValue"
        type="line"
        show-in-legend="false"
        color="#9090FF"
      />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="bottom"
        visible="false"
      />
      <DxSeriesTemplate name-field="containerName" visible="false" />
      <DxExport :enabled="true" />
      <DxTitle text="Tank History">
        <DxSubtitle text="(Volume %)" />
      </DxTitle>
      <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="10000"/>
      <!-- show-zero will reset the chart to show a value axis (y-axis) down to 0 value -->
      <DxValueAxis show-zero="true">  
      <!-- <DxValueAxis> -->
          <!-- <DxStrip :start-value="0" :end-value="345" color="#FF000010" /> -->
          <DxStrip :start-value="0" :end-value="20" color="#FF000010" />
          <!-- <DxStrip :start-value="345" :end-value="690" color="#FFFF0015" /> -->
      </DxValueAxis>
    </DxChart>
  </div>

  <div class="content-block dx-card responsive-paddings">

    <DxDataGrid class="dx-card wide-card"
      :data-source="currentHistory"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :allow-column-reordering="true"
      no-data-text="Loading ..."
      @selection-changed="masterSelectionChanged"
      @row-click="dataRowToggle"
      @initialized="saveGridInstance"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="rep_container_history_single_v_oper"
      />
      <DxExport :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxColumn data-field="containerId" :visible="false" :show-in-column-chooser="false"/> 
      <DxColumn data-field="historyDateUnix" :visible="false" :show-in-column-chooser="false"/>
      <DxColumn data-field="containerName" caption="Name" min-width="125"/>
      <DxColumn data-field="Downhole LSD" caption="Downhole LSD" min-width="125"/> 
      <DxColumn data-field="historyDate" caption="Date" min-width="125"/> 
      <DxColumn data-field="Product" caption="Product" min-width="125"/> 
      <DxColumn data-field="Volume (%)" caption="Volume (%)" min-width="125"/> 
      <DxColumn data-field="DTE" caption="DTE" min-width="125"/> 
      <DxColumn data-field="Injection Avg" caption="Injection" min-width="125"/> 
      <DxColumn data-field="Injection Target" caption="Injection Target" min-width="125"/> 

    </DxDataGrid>
    <div v-for="s in currentHistory[0]" :key="s.containerId">
      {{ s.containerName }}
    </div>
  </div>

</template>

<script>

import {DxToolbar, DxItem as DxToolbarItem} from 'devextreme-vue/toolbar';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxZoomAndPan,
  DxStrip
} from "devextreme-vue/chart";
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  // DxScrolling,
  DxColumnChooser,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    DxToolbar,
    DxToolbarItem,
    DxChart,
    DxCommonSeriesSettings,
    DxSeriesTemplate,
    DxExport,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxValueAxis,
    DxZoomAndPan,
    DxStrip,
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    // DxScrolling,
    DxColumnChooser,
    // DxButton,
    DxSelectBox,
    DxStateStoring
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {

      dataGridInstance: null,
      currentMode: 'widget',
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      detailButtonOptions: {
        icon: 'textdocument',
        onClick: () => {
          this.gotoDetail();
        },
      },
      containerName:"-",
      containerDownhole:"-",
      currentContainer: [],
      currentProperties: [],
      currentHistoryType: 90,
      historyTypes:[
        {id: 90, name: "90 days"},
        {id: 30, name: "30 days"},
        {id: 7, name: "7 days"},
      ],
      properties:[],
      currentHistory:[],
    };
  },
  computed: {
    currentRecordId() {
      return parseInt(this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)); 
    },
    containers() {
      return this.$store.getters["containers/getContainerHistory"];
    },
    containerVolumeHistory(){
      var data = this.$store.getters["containers/getContainerHistory"];
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-this.currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (%)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    },  
  },
  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} %</span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    getPropertyVal(propName){
      let retVal="-";
      // console.log(this.currentHistory);
      if (this.containers[0].containerName !== undefined && this.containers[0].containerName !== null)
      {
        retVal = this.currentHistory[this.currentHistory.length-1][propName];
      }
      return retVal;
    },
    parseContainerHistory(){
      var data = this.$store.getters["containers/getContainerHistory"];
      const history = [];
      var dateToday = new Date();
      var dateCriteria = new Date();
      dateCriteria.setDate(dateToday.getDate()-this.currentHistoryType);
      
      while (dateCriteria<=new Date())
      {
        const h = {
            containerId: this.currentRecordId,
            containerName: data[0].containerName,
            historyDate: dateCriteria.getFullYear() + "-" + ("0"+(dateCriteria.getMonth()+1)).slice(-2) + "-" + ("0" + dateCriteria.getDate()).slice(-2),
            historyDateUnix: Date.parse(dateCriteria.getFullYear() + "-" + ("0"+(dateCriteria.getMonth()+1)).slice(-2) + "-" + ("0" + dateCriteria.getDate()).slice(-2)),
          };
        history.push(h);
        dateCriteria.setDate(dateCriteria.getDate()+1);
      }
      
      dateCriteria.setDate(dateToday.getDate()-this.currentHistoryType);
      for (const key in data) {
        const d = Date.parse(data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2));
        const h = history.filter((data) => data.historyDateUnix == d)
        const p = this.properties.filter((prop) => prop.propertyId == data[key].propertyId)
        h[0][p[0].propertyName] = data[key].propValue;
      }
      this.currentHistory = history;
    },
    updateHistType(e){
      this.currentHistoryType=e.value;
    },
    refreshData(){
      this.loadPageData();
    },
    gotoDetail() {
      this.$router.push({ name: 'reporting-containers-status-details', params: {id: this.currentRecordId}});
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainerHistory", this.currentRecordId);
        var data = this.$store.getters["containers/getContainerHistory"];
        await this.$store.dispatch("properties/loadProperties");
        this.properties = this.$store.getters["properties/getProperties"];
        this.parseContainerHistory();
        this.containerName = data[0].containerName;
        this.containerDownhole = this.getPropertyVal('Downhole LSD');
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }   
    },
  },
};
</script>

<style scoped>
.tb{
  margin: 40px 0 0 0;
}
.menubar{
  padding: 0 60px 0 60px ;
}

</style>

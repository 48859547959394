export  default {
    setContainerTypes(state, payload) {
        state.containerTypes = payload;
      },
    updateContainerType(state, payload) {
        const containerType = state.containerTypes.find(x=>x.containerTypeId===payload.containerTypeId);
        containerType.containerTypeName = payload.containerTypeName;
      },
    addContainerType(state, payload) {
        state.containerTypes.push(payload);
      },
    setCurrentContainerType(state, payload) {
        state.currentContainerType = payload;
      },
};
export  default {
  setPermContainerGroups(state, payload) {
    state.permContainerGroups = payload;
  },
  setCurrentPerm(state, payload) {
    state.currentPerm = payload;
  },
  setContainerOperAreas(state, payload) {
    state.containerOperAreas = payload;
  },
};
export default {
 
  async addSiteOtodata(context, payload ) 
  {
    const newRequest = {
      containerName: payload.containerName,
      deviceSerial: payload.deviceSerial,
      operatorName: payload.operatorName,
      areaName: payload.areaName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/sites/addotodata`;
    
    // console.log("NEW SITE" , newRequest);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();
    const responseData = {
      recordsAffected: responseJson.recordsAffected,
      message: responseJson.message,
    };
    context.commit('addSite', responseData);
    
  },

  async decommissionSite(context, payload ) 
  {
    
    const newRequest = {
      containerName: payload.containerName,
      deviceSerial: payload.deviceSerial,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/sites/decommission`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();
    const responseData = {
      recordsAffected: responseJson.recordsAffected,
      message: responseJson.message,
    };
    context.commit('decommissionSite', responseData);
    
  },
};

<template>

  <div v-if="(getUserView()=='operator')">
      <DataOperator />
  </div>
  <div v-else>
      <DataDefault />
  </div>
 
</template>

<script>
import DataDefault from "./container-history-single-v-default.vue";
import DataOperator from "./container-history-single-v-operator.vue";

export default {
  created() {
    this.getUserView();
  },
  components: {
    // eslint-disable-next-line
    DataDefault, 
    // eslint-disable-next-line
    DataOperator,
  },
  methods:{
    getUserView()
    {
      var token = this.$store.getters["auth/getToken"];
        
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
  
      const tokenDetails = JSON.parse(jsonPayload);
      
      // console.log("TOKEN", tokenDetails);
      return tokenDetails.View.toLowerCase();
    }
  }
};
</script>

<style scoped>
  .ph { /* page header */
    height: 50px;
  } 
  
</style>

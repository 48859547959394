<template>
  <div class="content-block dx-card" >
    <h2 class="content-block">Area Details</h2>
    <DxToolbar class="menubar">
      <DxItem
          :options="cancelButtonOptions"
          location="before"
          widget="dxButton"
        />
      <DxItem
          :options="saveButtonOptions"
          location="before"
          widget="dxButton"
        />
    </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <!-- <form action="/url" method="GET">
        <input id="data" type="text">
    </form> -->
    <DxForm id="form" 
        :form-data="areas" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['areaId', 'areaName', 'regionId']">
      >
      <DxSimpleItem data-field="areaId"  :editor-options="{readOnly: true}" />
      <DxSimpleItem data-field="areaName" />
      <DxSimpleItem data-field="regionId" editor-type="dxSelectBox" :editor-options="regionFieldOptions()" />
      <DxSimpleItem data-field="officeId" editor-type="dxSelectBox" :editor-options="officeFieldOptions()" />
    </DxForm>
  </div>
  <DxLoadPanel
    v-model:visible="loadingVisible"
    :message="Loading"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    :hide-on-outside-click="false"
    :on-shown="onLoadPanelShown"
    :on-hidden="onLoadPanelHidden"
    shading-color="rgba(0,0,0,0.4)"
  />
  <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMessage"
      v-model:type="toastType"
      position="bottom center"
    />
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
// import notify from 'devextreme/ui/notify';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxToast } from 'devextreme-vue/toast';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxLoadPanel,
    DxToast
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      loadingVisible: false,
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      toastPosition: {
          at: "bottom",
          my: "top",
          of: "#pwreset" },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          //this.refreshData();
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          this.loadingVisible = true;
          if (this.areas.areaId==0)
          {
            this.$store.dispatch('areas/addArea', this.areas);
            this.$emit('recordUpdated');
          }
          else
          {
            this.$store.dispatch('areas/updateArea', this.areas);
            this.$emit('recordUpdated');
          }
          const rc = this.$store.getters["areas/getResponseCode"];
          console.log("GET RESPONSE",rc)
          
          if (rc >= 200 && rc <= 299)
          {
            this.toastType = 'success';
            this.toastMessage = 'Record saved (' + rc + ').';
            this.toastVisible = true;
          }
          else{
            this.toastType = 'error';
            this.toastMessage = 'Error saving record (' + rc + ').';
            this.toastVisible = true; 
          }
      
          this.loadingVisible = false;
        },
      },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    areas() {
      return this.$store.getters["areas/getCurrentArea"];
    },
    regions() {
      return this.$store.getters["regions/getRegions"];
    },
    offices() {
      return this.$store.getters["offices/getOffices"];
    }
  },
  methods: {
    
    onLoadPanelShown() {
      setTimeout(() => { this.loadingVisible = false; }, 10000);
    },
    onLoadPanelHidden() {
      // 
    },
    regionFieldOptions() {
      return {
        items: this.regions,
        valueExpr: "regionId",
        displayExpr: "regionName"
      }
    },
    officeFieldOptions() {
      return {
        items: this.offices,
        valueExpr: "officeId",
        displayExpr: "officeName",
      }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      this.loadingVisible = true;
      try {
        await this.$store.dispatch("areas/loadArea", { id: this.currentRecordId });
        await this.$store.dispatch("regions/loadRegions", {forceRefresh: refresh});
        await this.$store.dispatch("offices/loadOffices", {forceRefresh: refresh});
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
      this.loadingVisible = false;      
    },
  },
};
</script>

<style scoped>
  .menubar{
    padding: 0 0 0 40px ;
  }
</style>

export default {
  async updateDivision(context, payload) {

    const newRequest = {
      divisionId: payload.divisionId,
      divisionName: payload.divisionName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/divisions`;
    // var url = `https://r3backendprd.azurewebsites.net/api/divisions/${payload.divisionId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateDivision', payload);
  },

  async addDivision(context, payload) {
    const newRequest = {
      divisionId: payload.divisionId,
      divisionName: payload.divisionName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/divisions`;

    // var url = `https://r3backendprd.azurewebsites.net/api/divisions`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.divisionId = responseJson.divisionId;
    context.commit('addDivision', payload);
  },

  async loadDivisions(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/divisions`;
    // var url = `https://r3backendprd.azurewebsites.net/api/divisions`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const division = {
        divisionId: responseJson[key].divisionId,
        divisionName: responseJson[key].divisionName,
        };

      responseData.push(division);
        
    }
    context.commit('setDivisions', responseData)
  },

  async loadDivision(context, payload ) {

    const id = payload.id;

    let division = {
      divisionId: 0,
      divisionName: "",
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/divisions/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/divisions/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      division = {
        divisionId: responseJson.divisionId,
        divisionName: responseJson.divisionName,
        };
    }
    context.commit('setCurrentDivision', division)
  },
};

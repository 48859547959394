<template>

  <div class="content-block dx-card responsive-paddings">
    <!-- <h2>Transfer Digi To Otodata</h2> -->

    <h6>Container Name : </h6>
    <DxTextBox v-model:value="container" />
    
    <h6>Device SerialNum : </h6>
    <DxTextBox v-model:value="existingDevice"/>
    
    <h6>Operator : </h6>
    <DxTextBox v-model:value="container" read-only="true"/>

    <h6>Area : </h6>
    <DxTextBox v-model:value="container" read-only="false"/>

    <DxButton class="btn" text="Save" @click="createSite()"/>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxTextArea
        :height="140"
        :read-only="true"
        v-model:value="newResponse"
      />
  </div>
</template>

<script>

import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from 'devextreme-vue/button';
import DxTextArea from 'devextreme-vue/text-area';

export default {
  components: {
    DxTextBox,
    DxButton,
    DxTextArea,
  },
  data() {
    return {
      container: "",
      newDevice: "",
      existingDevice: "",
      result: null,
      newResponse: null,
    };
  },
  created() {
    this.loadPageData();
  },
  methods: {
    async createSite(){
      // const newSite= {
      //   containerName: this.newContainer,
      //   deviceSerial: this.newDevice,
      //   operatorName: this.newOper,
      //   areaName: this.newArea
      // }
      // console.log("CREATE", newSite);
      // await this.$store.dispatch('utils/addSiteOtodata', newSite);
      // this.$emit('recordUpdated');
      // this.result = await this.$store.getters["utils/getAddSiteResults"];
      // if (this.result.recordsAffected==-1)
      // {
      //   this.newResponse = "Record created. " + Date().toLocaleString() + "\n" + this.result.message;
      // }
      // else
      // {
      //   this.newResponse = "Record not created. " + Date().toLocaleString() + "\n" + this.result.message;
      // }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        // await this.$store.dispatch("areas/loadAreas");
        // this.areas = this.$store.getters["areas/getAreas"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.tb{
  margin: 10px 0 0 2px;
}
.btn{
  margin: 50px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}

</style>


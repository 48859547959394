export default {
 
  async loadUsage(context, payload) {
    const newRequest = {
      startDate: payload.startDate.getFullYear() + "-" + (payload.startDate.getMonth() + 1) + "-" + payload.startDate.getDate() + "T00:00:00",
      //startDate: payload.startDate.getFullYear() + "-" + (payload.startDate.getMonth() + 1) + "-" + payload.startDate.getDate(),
      endDate: payload.endDate.getFullYear() + "-" + (payload.endDate.getMonth() + 1) + "-" + payload.endDate.getDate() + "T23:59:59",
      //endDate: payload.endDate.getFullYear() + "-" + (payload.endDate.getMonth() + 1) + "-" + payload.endDate.getDate(),
      operatorName: payload.operatorName
    };
    console.log ("REQUEST", newRequest)

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/usage`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const usage = {
        containerId: responseJson[key].containerId,
        containerName: responseJson[key].containerName,
        operatorName: responseJson[key].operatorName,
        areaName: responseJson[key].areaName,
        product: responseJson[key].product,
        downholeLSD: responseJson[key].downhole,
        startDate: responseJson[key].startDate.substring(0, responseJson[key].startDate.indexOf("T")),
        endDate: responseJson[key].endDate.substring(0, responseJson[key].endDate.indexOf("T")),
        volumeStart: responseJson[key].volumeStart,
        volumeEnd: responseJson[key].volumeEnd,
        volumeFill: responseJson[key].volumeFill,
        };

      responseData.push(usage);
        
    }

    context.commit('setUsage', responseData);
  },

};

<template>
  <h2 class="content-block">Group Details</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="group" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['groupId', 'groupName', 'active']">
      >
      <DxSimpleItem data-field="groupId" :editor-options="{readOnly: true}" />
      <DxSimpleItem editor-type="dxTextBox" data-field="groupName"/>
      <!-- <DxSimpleItem editor-type="dxCheckBox" data-field="active"/> -->
    </DxForm>
    
  </div>
  <div class="content-block dx-card responsive-paddings component">
    <SelectTree ref="perm" :groupId="currentRecordId" />
  </div>

  <!-- <DxToast
    v-model:visible="toastVisible"
    v-model:message="toastMessage"
    v-model:type="toastType"
    :position="toastPosition"
  /> -->
  
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
// import { DxToast } from 'devextreme-vue/toast';
import SelectTree from "@/components/permissions/group-perm.vue" ;
import notify from 'devextreme/ui/notify';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    // DxToast, 
    SelectTree,
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      loadingVisible: false,
      // toastVisible: false,
      // toastMessage: '',
      // toastType: 'info',
      // toastPosition: {
      //     at: "bottom",
      //     my: "top",
      //     of: "#pwreset" },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          
          if (this.group.groupId==0)
          {
            this.$store.dispatch('groups/addGroup', this.group);
            this.$emit('recordUpdated');
          }
          else
          {
            // console.log("UPDATEGROUP",this.group)
            this.$store.dispatch('groups/updateGroup', this.group);
            this.$emit('recordUpdated');
            
            const rc = this.$store.getters["groups/getResponseCode"];
            console.log("RESPONSE",rc);
            if (rc >= 200 && rc <= 299)
            {
              notify('Record saved (' + rc + ').', "success", 5000);
            }
            else{
              notify('Error saving record (' + rc + ').', "error", 5000);
            }
          }
          this.$refs.perm.savePermissions()

        },
      },
    };
  },
  computed: {
    isNewRec(){
      return (this.currentRecordId==0)
    },
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    group() {
      return this.$store.getters["groups/getGroup"];
    },
  },
  methods: {
    refreshData(){
      this.loadPageData();
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("groups/loadGroup", { id: this.currentRecordId });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.tb{
  margin: 10px 0 30px 0px;
}
.component {
  max-height: 40vh;
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #343434;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<template>
  <div class="ph">
  <!-- <div> -->
    <div class="pagetitle">Reporting ❯ Tank Status</div>
    <!-- unicode U+276F : ❯ -->
    <!-- <h2 class="content-block">Reporting ❯ Tank Status</h2>  -->
    <DxLoadPanel
        :hide-on-outside-click="true"
        v-model:visible="isLoadPanelVisible"
        message="Loading..."
    />
  </div>
  <div>
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="containers"
      :key-expr="containerId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :allow-column-reordering="true"
      no-data-text="Loading ..."
      @row-click="showDetails"
      @initialized="saveGridInstance"
      @content-ready="dataReady"
      :style="{ 'max-height': datagridHeight }"
    >
      <!-- @selection-changed="masterSelectionChanged" -->
      <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="rep_status_container_grid_view_operator"
        />

      <DxToolbar>
        <!-- <DxItem location="before" template="filterStatusCriticalTemplate" />
        <DxItem location="before" template="filterStatusMarginalTemplate" />
        <DxItem location="before" template="filterStatusGoodTemplate" />
        <DxItem location="before" template="filterStatusAllTemplate" /> -->
        <DxItem location="after"> <div class="mininote">Rows: {{ rowCount }} </div></DxItem>
        
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>

      <!-- <template #filterStatusCriticalTemplate>
        <DxButton text="❌ " hint="Filter Critical Values" @click="filterToggle('❌')" />
      </template>
      <template #filterStatusMarginalTemplate>
        <DxButton text="⚠️ " hint="Filter Marginal Values" @click="filterToggle('⚠️')" />
      </template>
      <template #filterStatusGoodTemplate>
        <DxButton text="✔️ " hint="Filter Good Values" @click="filterToggle('✔️')" />
      </template>
      <template #filterStatusAllTemplate>
        <DxButton text="All" hint="All Statuses" @click="filterToggle('')" />
      </template> -->
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" hint="Refresh Data" @click="refreshData" />
      </template>
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>
      <template #rowCountTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>

      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" />     <!-- mode="virtual" -->
      <DxColumnFixing :enabled="true" />
      <DxPager
        :show-info="true"
        :show-page-size-selector="true"
        :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true"
      />
      
      <!-- <DxColumn id = "stat" data-field="containerStatus" fixed="true" caption="" width="55px" alignment="left" :allow-resizing="false" :allow-filtering="true" :show-in-column-chooser="false" :allow-reordering="false"/>             -->
      <DxColumn data-field="operator" caption="Operator" min-width="125"/> 
      <DxColumn data-field="area" caption="Area" min-width="125"/>
      <!-- <DxColumn data-field="division" caption="Division" :visible="false"/> -->
      <!-- <DxColumn data-field="containerType" caption="Type" :visible="false"/> -->
      <DxColumn data-field="serialnum" caption="Device" :visible="false" min-width="125"/>
      <DxColumn data-field="containerName" caption="Name" :allow-reordering="true" min-width="150"/>
      <DxColumn data-field="Downhole LSD" caption="Downhole LSD" alignment='left' min-width="150" :visible="true"/>      
      <DxColumn data-field="Product" caption="Product" alignment='left' min-width="100" :visible="true"/>         
      <DxColumn data-field="Capacity" caption="Capacity" alignment='left' min-width="100"/>  
      <DxColumn data-field="Volume (%)" caption="Volume (%)" alignment='left' min-width="100"/>  
      <DxColumn data-field="DTE" caption="DTE" alignment='left' min-width="100"/>   
      <DxColumn data-field="Injection Avg" caption="Injection" alignment='left' min-width="100"/>
      <DxColumn data-field="Injection Target" caption="Target Injection" alignment='left' :visible="true"/>
      <DxColumn data-field="Last Device Communication" caption="Last Communication" alignment='left' min-width="100" :visible="true"/>

    </DxDataGrid>
  </div> <!-- end desktop vers -->
  
  <div>
    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="800"
      :height="600"
      :title=this.getContainerVal(this.currentContainer.containerName)
      content-template="tank-info"
    >
      <DxLoadPanel
        :position="position"
        v-model:visible="loadingVisible"
        :show-indicator="showIndicator"
        :show-pane="showPane"
        :shading="shading"
        :hide-on-outside-click="hideOnOutsideClick"
        :on-shown="onShown"
        :on-hidden="onHidden"
        shading-color="rgba(0,0,0,0.4)"
      />
    <!-- container=".dx-viewport" -->
      
      <template #tank-info>
        <DxScrollView
          :width="750"
          :height="600"  
          direction="both">  <!-- or 'horizontal' | 'vertical' -->

          <div class="pheader">Downhole LSD : {{this.getContainerVal(this.currentContainer['Downhole LSD'])}}</div>
            
          <!-- Here goes long content -->
        
        <!-- <div class="divGroup" id ="containerInfo"> -->
        
            <!-- <div class="pval">{{this.getContainerVal(this.currentContainer.containerName)}}</div>
            <div class="pheader">Downhole LSD</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Downhole LSD'])}}</div>
            <div class="pheader">Operator</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer.operator)}}</div>
            <div class="pheader">Area</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer.area)}}</div>
            <div class="pheader">Device</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer.serialnum)}}</div>
            <div class="pheader">Product</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Product'])}}</div>
            <div class="pheader">Last Communication</div> 
            <div class="pval">{{this.getPropertyVal('Last Device Communication')}}</div> -->
          <!-- </div>

           <div class="divGroup" id ="volumeInfo">
            <div class="pheader">Capacity</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Capacity'])}}</div>
            <div class="pheader">Volume (%)</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Volume (%)'])}}</div>
          </div> -->

          <!-- <div class="divGroup" id ="injectionInfo">
            <div class="pheader">Injection</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Injection Avg'])}}</div>
            <div class="pheader">Injection Target</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['Injection Target'])}}</div>
            <div class="pheader">Days To Empty</div>
            <div class="pval">{{this.getContainerVal(this.currentContainer['DTE'])}}</div>
          </div>-->

          <div class="divGroup" id ="history"> 
            <!-- graphs-->
            <div v-if="!showHistory">
              <DxButton text="Load Volume History" type="default" hint="Volume History" @click="loadHistory()" width="100%" styling-mode="outlined" />
            </div>
            <div v-else >
              <DxChart :data-source="containerVolumeHistory">
                <DxZoomAndPan argument-axis="none" value-axis="none"/>
                <DxCommonSeriesSettings
                  argument-field="historyDate"
                  value-field="propValue"
                  type="line"
                  show-in-legend="false"
                  color="#9090FF"
                />
                <DxLegend
                  vertical-alignment="bottom"
                  horizontal-alignment="center"
                  item-text-position="bottom"
                  visible="false"
                />
                <DxSeriesTemplate name-field="containerName" visible="false" />
                <!-- <DxExport :enabled="true" /> -->
                <DxTitle text="Tank History">
                  <DxSubtitle text="(Volume %)" />
                </DxTitle>
                <!-- DXPopUp has a very high zIndex, set this higher or it won't show -->
                <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" zIndex="20000"/>
                <!-- <DxValueAxis :endValue="100" :startValue="0">   -->
                <!-- <DxValueAxis show-zero="true" :endValue="100" :startValue="0">   -->
                <!-- <DxValueAxis show-zero="true" :endValue="100" :startValue="0" :visual-range="[0,100]">   -->
                <!-- <DxValueAxis :start-value="0" :end-value="15" :whole-range="[0,80]" :visual-range="[0,80]">   -->
                <!-- <DxValueAxis :visual-range="[0, 80]" v-model:visual-range-update-mode="keep">   -->
                <DxValueAxis :visual-range="[0, 80]">  
                  <DxStrip :start-value="0" :end-value="15" color="#FF000010" />
                </DxValueAxis>
              </DxChart>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>  
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar,
  DxItem,
  DxStateStoring,
  DxMasterDetail,
  DxPaging,
  DxPager,
  DxColumnFixing
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import DxBox from 'devextreme-vue/box';
import DxTextBox from "devextreme-vue/text-box";
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DxChart, {
  DxCommonSeriesSettings,
  DxSeriesTemplate,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxValueAxis,
  DxSeries,
  DxZoomAndPan,
  DxStrip
} from "devextreme-vue/chart";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxItem,
    DxButton,
    DxStateStoring,
    // DxMasterDetail,
    // DxBox, 
    // DxTextBox,
    // DxPaging,
    DxPager,
    DxColumnFixing,
    DxPopup, 
    // DxPosition, 
    // DxToolbarItem,
    DxScrollView,
    DxChart,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxValueAxis,
    DxStrip,
    DxZoomAndPan
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      currentMode: 'widget',
      dataGridInstance: null,
      currentContainer: "",
      currentProperties: null,
      loadingVisible: false,
      isLoadPanelVisible: false,
      datagridHeight: '40vh',
      rowCount: 0,
      popupVisible: false,
      showHistory: false,
      // loadpanel
      // loadingVisible : false,
      position : { of: '#history' },
      showIndicator : true,
      shading : true,
      showPane : true,
      hideOnOutsideClick : false,
    };
  },
  computed: {
    containers() {
      return this.$store.getters["containers/getContainersFlatProperties_ViewOperator"];
    },
    containerVolumeHistory(){
      const currentHistoryType = 30;
      var data = this.$store.getters["containers/getContainerPropertyHistory"];
      const volumeHist = [];
      var dateCriteria = new Date();
      dateCriteria.setDate(dateCriteria.getDate()-currentHistoryType);

      for (const key in data) {
        if (data[key].propertyName==="Volume (%)" && data[key].historyDate>=dateCriteria)
        {
          const hist = {
            historyId:  data[key].historyId,
            customerId:  data[key].customerId,
            containerId: data[key].containerId,
            containerName: data[key].containerName,
            propertyId: data[key].propertyId,
            historyDate: data[key].historyDate.getFullYear() + "-" + ("0"+(data[key].historyDate.getMonth()+1)).slice(-2) + "-" + ("0" + data[key].historyDate.getDate()).slice(-2),
            propValue: parseFloat(data[key].propValue),
            historyType: data[key].historyType,
            notes: data[key].notes,
            propertyName: data[key].propertyName,
          };
          volumeHist.push(hist);
        }
      }
      return volumeHist;
    }, 
  },
  methods: {
    dataReady(){
      this.rowCount = this.dataGridInstance.totalCount();
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    changeResizingMode(data) {
      this.currentMode = data.value;
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
      this.dataGridInstance.columnOption(0, 'allowHeaderFiltering', false);
    },
    removeFilter(){
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    refreshData(){
      this.loadPageData();
    },
    async filterToggle(filterArg)
    {
      this.dataGridInstance.columnOption(0, 'filterValue', filterArg);
    },
    showDetails(e){
      this.currentContainer = e.component.getSelectedRowKeys()[0];
      // console.log("CURRENT")
      // console.log(this.currentContainer)
      this.showHistory = false;
      this.popupVisible = true;
    },
    loadHistory(){
      this.loadingVisible = true;
      if (this.currentContainer.containerId != null && this.currentContainer.containerId !== undefined && this.currentContainer.containerId > 0)
      {
        this.$store.dispatch("containers/resetContainerPropertyHistory");
        this.$store.dispatch("containers/loadContainerPropertyHistory", {container: this.currentContainer.containerId, propertyName: 'Volume (%)'});
        this.showHistory = true;
      }
      this.loadingVisible = false;
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
          <div>
            <div style="width: 100px;padding:5px;text-align: center;"><span style="font-size:18px;font-weight:bold;">${pointInfo.value} </span></div>
            <div style="width: 100px;padding:5px;text-align: center;">${pointInfo.argumentText}</div>
          </div>`
      };
    },
    getContainerVal(propVal){
      //console.log(propVal, propVal.length)
      // && (propVal.length > 0 || parseInt(propVal)!=0)
      if (propVal !== undefined && propVal != null )
      {

        return propVal;
      }
      else
      {
        return '-';
      }
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainersStatus", {forceRefresh: refresh});
        // console.log(this.containers[0]);
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
    },
  },
};

</script>

<style scoped>
  .ph { /* page header */
    height: 20px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }

  .ph { /* page header */
    /* height: 50px; */
    /* margin-bottom: 50px; */
    margin: 20px 0px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  .divGroup {
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    /* border-color: rgb(255, 100, 0); */
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.2); */
    /* text-align: center; */
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  .pagetitle {
    text-align: left;  
    font-size: 30px;
    font-weight: lighter;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px; 
    /* font-weight: 300; */
    /* color: rgb(255, 125, 0); */
  }
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .pheader {
    font-size: 18px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 16px;
    z-index: 20;
  }
  .pval {
    font-size: 22px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .floater{
    position: absolute;
    /* top: 10px; */
    right: 0;
    z-index: 10;
  }
</style>

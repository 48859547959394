import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            devices: [{
                id: '100-000-000',
                container_id: '0000-0000-0000-0001',
                type: 'temperature'
            },{
                id: '200-000-000',
                container_id: '0000-0000-0000-0002',
                type: 'pressure'
            },{
                id: '300-000-000',
                container_id: '0000-0000-0000-0003',
                type: 'motion'
            }]
        };
    },
    mutations,
    actions,
    getters
};
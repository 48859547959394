export  default {
    getGroups(state) {
        return state.groups;
    },
    getGroup(state) {
        return state.group;
    },
    getResponseCode(state) {
        return state.responseCode;
    },
};
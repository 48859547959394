export default {
  async updateDeviceType(context, payload) {

    const newRequest = {
      deviceTypeId: payload.deviceTypeId,
      deviceTypeName: payload.deviceTypeName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceTypes/${payload.deviceTypeId}`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceTypes/${payload.deviceTypeId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateDeviceType', payload);
  },

  async addDeviceType(context, payload) {
    const newRequest = {
      deviceTypeId: payload.deviceTypeId,
      deviceTypeName: payload.deviceTypeName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceTypes`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceTypes`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.deviceTypeId = responseJson.deviceTypeId;
    context.commit('addDeviceType', payload);
  },

  async loadDeviceTypes(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/deviceTypes`;
    // var url = `https://r3backendprd.azurewebsites.net/api/deviceTypes`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const deviceType = {
        deviceTypeId: responseJson[key].deviceTypeId,
        deviceTypeName: responseJson[key].deviceTypeName,
        };

      responseData.push(deviceType);
        
    }
    context.commit('setDeviceTypes', responseData)
  },

  async loadDeviceType(context, payload ) {

    const id = payload.id;

    let deviceType = {
      deviceTypeId: 0,
      deviceTypeName: "",
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/deviceTypes/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/deviceTypes/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      deviceType = {
        deviceTypeId: responseJson.deviceTypeId,
        deviceTypeName: responseJson.deviceTypeName,
        };
    }
    context.commit('setCurrentDeviceType', deviceType)
  },
};

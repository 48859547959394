<template>
<div>
    <!-- <div class="form"> -->
      <h5 class="component-desc">Permissions</h5>
      <DxTreeView
        id="treeview"
        :ref="treeViewRef"
        :items="permData"
        :show-check-boxes-mode="normal"
        :selection-mode="multiple"
        :select-nodes-recursive="true"
        :select-by-click="true"
        @selection-changed="treeViewSelectionChanged"
        @content-ready="treeViewContentReady"
      >
        <template #item="item">
          {{ item.data.name }}
          <!-- {{ item.data[dataName[0]] }} -->
          <!-- {{ item.data[dataName[0]] + ' (' + item.data[dataName[1]] + ')' }} -->
          <!-- {{ item.data.fullName + ' (' + item.data.position + ')' }} -->
        </template>
      </DxTreeView>
    <!-- </div> -->
  </div>
</template>

<script>
import operators from '@/store/modules/operators';
import DxTreeView from 'devextreme-vue/tree-view';

const treeViewRef = 'treeView';

export default {
  components: {
    DxTreeView,
  },
  props: ['groupId'],
  data() {
    return {
      selected: [],
      treeViewRef,
      permData: [],
      areas: [],
      operators: [],
      operAreas: [],
      currPerm: [],
    };
  },
  computed: {
    treeView() {
      return this.$refs[treeViewRef].instance;
    },
  },
  created(){
    // console.log("***** CREATE COMPONENT *****");
    this.loadComponentData();
  },
  methods: {
    savePermissions(){
      const tvn = this.treeView.getSelectedNodes();
      const p = this.currPerm.filter((data) => data.groupId==this.groupId);
      let key = 0;
      for (key in tvn)
      {
        if (tvn[key].key.toString().indexOf(':') > -1)
        {
          const o = (tvn[key].key).split(":")[0];
          const a = (tvn[key].key).split(":")[1];
          // console.log("OPER",o);
          // console.log("AREA",a);
          //if the current selection is NOT in p(current perm)
          if (p.findIndex((data) => data.operatorId == o && data.areaId == a) < 0)
          {
            //add a new permission
            const newPerm = {
              permId: 0,
              groupId: this.groupId,
              operatorId: o,
              areaId: a
            };
            this.$store.dispatch("permContainerGroups/addPermission", newPerm);

          }
        }
      }
      let curr = 0;
      for (curr in p)
      {
        // console.log("CURR",p[curr]);
        key = 0;
        let found = false;
        for (key in tvn)
        {
          if (tvn[key].key.toString().indexOf(':') > -1)
          {
            const o = (tvn[key].key).split(":")[0];
            const a = (tvn[key].key).split(":")[1];
            // console.log("CHECK SELECTED",o,a);
            if (p[curr].operatorId==o && p[curr].areaId==a)
            {
              // console.log("FOUND",o,a);
              found=true;
            }
          }
        }
        if (!found)
        {
          //remove
          this.$store.dispatch("permContainerGroups/deletePermission", p[curr].permId);
        }        
      }
    },

    treeViewSelectionChanged(e) {
      this.syncSelection(e.component);
    },

    treeViewContentReady(e) {
      this.syncSelection(e.component);
    },

    syncSelection(treeView) {
      const selected = treeView.getSelectedNodes()
        .map((node) => node.itemData);

      this.selected = selected;
    },

    showCheckBoxesModeValueChanged(e) {
      if (e.value === 'selectAll') {
        this.selectionModeValue = 'multiple';
        this.isRecursiveDisabled = false;
      }
      this.isSelectionModeDisabled = e.value === 'selectAll';
    },

    selectionModeValueChanged(e) {
      if (e.value === 'single') {
        this.selectNodesRecursiveValue = false;
        this.treeView.unselectAll();
      }
      this.isRecursiveDisabled = e.value === 'single';
    },

    async loadComponentData(refresh = false) {
      try {
        // await this.$store.dispatch("permContainerGroups/loadPermContainerGroup", { id: this.currentRecordId });
        await this.$store.dispatch("operators/loadOperators");
        await this.$store.dispatch("areas/loadAreas");
        await this.$store.dispatch("permContainerGroups/loadContainerOperAreas");
        await this.$store.dispatch("permContainerGroups/loadPermContainerGroups");

        // await this.$store.dispatch("groups/loadGroups");
        this.areas = this.$store.getters["areas/getAreas"];
        this.operators = this.$store.getters["operators/getOperators"];
        this.operAreas = this.$store.getters["permContainerGroups/getContainerOperAreas"];
        this.currPerm = this.$store.getters["permContainerGroups/getPermContainerGroups"];

        this.setupTreeSource();

      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },

    setupTreeSource()
    {
      this.permData=[{
        id: -1,
        name: '',
        expanded: true,
        items:[],
        // selected: null
      }];
      
      for (const o in this.operators) {        
        const operator = {
          id: this.operators[o].operatorId,
          name: this.operators[o].operatorName,
          expanded: false,
          items:[],
          // selected: null
          };
          for (const a in this.areas) {  
            if (this.operAreas.findIndex((data)=>data.operatorId == operator.id && data.areaId == this.areas[a].areaId) >= 0)
            {
              const i = this.currPerm.findIndex((data)=>data.groupId == this.groupId && data.operatorId == operator.id && data.areaId == this.areas[a].areaId)

              const area = {
              id: operator.id + ':' + this.areas[a].areaId,
              name: this.areas[a].areaName,
              expanded: false,
              items:[],
              selected: (i >= 0),
              dbKey: (i >= 0) ? this.currPerm[i].permId : -1
              };
              
              operator.items.push(area);
            }
            // data.push(permarea);
          }
        this.permData[0].items.push(operator);
        // data.push(permarea);
      }

    }
  },
}
</script>

<style scoped>
  .component-desc{
    font-weight: lighter;
  }
  
</style>

import common from '../common.js';

export default {
  async loadLogInjections(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'loginjections')
    const responseData = [];
    
    for (const key in responseJson) 
    {
      
      const log = {
        logId: responseJson[key].logId,
        logDate: responseJson[key].logDate.substring(0,10),
        containerId: responseJson[key].containerId,
        historyDate: responseJson[key].historyDate.substring(0,10),
        volhist: responseJson[key].volhist,
        volcurr: responseJson[key].volcurr,
        fillhist: responseJson[key].fillhist,
        fillcurr: responseJson[key].fillcurr,
        histdays: responseJson[key].histDays,
        avgInj: responseJson[key].avgInj,
        containerName: responseJson[key].containerName,
        serialnum: responseJson[key].serialNum,
        operatorName: responseJson[key].operatorName,
        areaName: responseJson[key].areaName,

      };
      responseData.push(log);        
    }
    
    context.commit('setLogInjections', responseData)
    
  },

};

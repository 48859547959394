export  default {
    setDeviceStatuses(state, payload) {
        state.deviceStatuses = payload;
      },
    updateDeviceStatus(state, payload) {
        const deviceStatus = state.deviceStatuses.find(x=>x.deviceStatusId===payload.deviceStatusId);
        deviceStatus.statusName = payload.statusName;
        deviceStatus.defaultDeviceStatus = payload.defaultDeviceStatus;
      },
    addDeviceStatus(state, payload) {
        state.deviceStatuses.push(payload);
      },
    setCurrentDeviceStatus(state, payload) {
        state.currentDeviceStatus = payload;
      },
};
import common from '../common.js';

export default {
  async updateDistrict(context, payload) {

    const newRequest = {
      districtId: payload.districtId,
      districtName: payload.districtName,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/districts/${payload.districtId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');  
      throw error;
    }
    context.commit('updateDistrict', payload);
  },

  async addDistrict(context, payload) {
    const newRequest = {
      districtId: payload.districtId,
      districtName: payload.districtName,
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/districts`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    context.commit('setResponseCode', response.status);
    // response.status ex. 200-OK
    // response.statusText

    if (!response.ok) {
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.districtId = responseJson.districtId;
    context.commit('addDistrict', payload);
  },

  async loadDistricts(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'districts')
    const responseData = [];

    for (const key in responseJson) 
    {
      const district = {
        districtId: responseJson[key].districtId,
        districtName: responseJson[key].districtName,
        };
      responseData.push(district);
    }
    context.commit('setDistricts', responseData)
  },

  async loadDistrict(context, payload ) 
  {
    const id = payload.id;

    let district = {
      districtId: 0,
      districtName: "",
    };

    if (id != 0)
    {
      const responseJson = await common.getRequest(context, payload, `districts/${id}`)
      district = {
        districtId: responseJson.districtId,
        districtName: responseJson.districtName,
        };
    }
    context.commit('setCurrentDistrict', district)
  },
};

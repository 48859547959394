export  default {
  setOffices(state, payload) {
    state.offices = payload;
  },
  updateOffice(state, payload) {
    const office = state.offices.find(x=>x.officeId===payload.officeId);
    office.officeName = payload.officeName;
    office.districtId = payload.districtId;
  },
  addOffice(state, payload) {
    state.offices.push(payload);
  },
  setCurrentOffice(state, payload) {
    state.currentOffice = payload;
  },
  setResponseCode(state, payload) {
    state.responseCode = payload;
  }
};
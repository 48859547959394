<template>
  <div>
    <DxButton text="Info" @click="menuChange(0)" styling-mode="text" icon="info"/><br>
    <DxButton text="Details" @click="menuChange(1)" styling-mode="text" icon="detailslayout"/><br>
    <DxButton text="Analytics" @click="menuChange(2)" styling-mode="text" icon="chart"/><br>
    <DxButton text="Requests" @click="menuChange(3)" styling-mode="text" icon="comment" />
  </div>
</template>

<script>

import DxButton from 'devextreme-vue/button';

export default {
  components: {
    DxButton
  },
  created() {  },
  data() {
    return {
      menu : [
        {id: 0, text: 'Info', icon: 'info'},
        {id: 1, text: 'Details', icon: 'textdocument'},
        {id: 2, text: 'Analytics', icon: 'chart'},
        {id: 3, text: 'Requests', icon: 'comment'}],
    };
  },
  methods:{
    menuChange(menu){
      this.$emit('menu-change', menu);
    }
  }
};

</script>

<style scoped>

</style>

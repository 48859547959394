<template>
  <div >
    <DxTextBox
      placeholder="Search details..."
      :width="auto"
      :show-clear-button="true"
      v-bind:value="searchVal"
      @value-changed = "searchChange"
    />
    <!-- @input = "searchChange" THIS IS THE BEST OPTION BUT I CAN'T GET THE TEXT VALUE FROM IT FOR SOME REASON -->
    <!-- @key-down = "searchChange" -->
    
    <DxScrollView :width="auto" :height="440" direction="both">  <!-- 'horizontal' | 'vertical' -->
      <div v-if="container">
        <div class="divGroup" id ="containerInfo">
          <div class="propHeader">Name</div>
          <div class="propVal">{{this.container.containerName}}</div>
          <div class="propHeader">Operator</div>
          <div class="propVal">{{this.container.operator}}</div>
          <div class="propHeader">Area</div>
          <div class="propVal">{{this.container.area}}</div>
          <div class="propHeader">Device</div>
          <div class="propVal">{{this.container.serialnum}}</div>
        </div>
      </div>
      <div class="divGroup" id ="containerInfo">
        <div v-for="prop in container.properties" :key="prop.containerPropId">
          <div v-if="prop.propertyName.toLowerCase().includes(searchVal.toLowerCase())">
            <div class="propHeader">{{prop.propertyName}}</div>
            <div class="propVal">{{prop.status}} {{prop.propValue}}</div>
            <div v-if="prop.propertyType==='NUMBER' && prop.useThresholds">
              <div class="propThreshold">Critical: {{prop.valueCritical}}</div>
              <div class="propThreshold">Marginal: {{prop.valueMarginal}}</div>
            </div> 
            <hr style="height:1px;border:1px solid #444;"/>
          </div>
        </div>
      </div>  
    </DxScrollView>
  </div>
</template>

<script>

import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTextBox from 'devextreme-vue/text-box';

export default {
  components: {
    DxScrollView,
    DxTextBox,
  },
  props: ['containerId', 'container'],
  created() { 
    this.loadPageData()
  },
  data() {
    return {
      // container: {},
      searchVal: "",
    };
  },
  // watch: {
  //   async containerId (){
  //     await this.$store.dispatch("containers/loadContainerStatus", this.containerId )
  //     this.container = this.$store.getters["containers/getContainerStatus"];
  //   },
  // },
  computed: {

  },
  methods: {
    loadPageData() {
    },
    searchChange(e){
      this.searchVal = e.value;     
    }
  },
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .propHeader {
    font-size: 16px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 6px;
    z-index: 20;
  }
  .propVal {
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .propThreshold {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.3);
    margin-left: 10px;
    margin-bottom: 2px;
    z-index: 20;
  }
  .hr {
    height:1px;
    border:1px solid #444;
  }
</style>

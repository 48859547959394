export  default {
    getAreas(state) {
        return state.areas;
    },
    getCurrentArea(state) {
        return state.currentArea;
    },
    getResponseCode(state) {
        return state.responseCode;
    },
};
export  default {
    setRegions(state, payload) {
        state.regions = payload;
      },
    updateRegions(state, payload) {
        // const area = state.areas.find(x=>x.areaId===payload.areaId);
        // area.regionId = payload.regionId;
        // area.areaName = payload.areaName;
      },
    addRegion(state, payload) {
        // state.areas.push(payload);
      },
};
export  default {
    setDivisions(state, payload) {
        state.divisions = payload;
      },
    updateDivision(state, payload) {
        const division = state.divisions.find(x=>x.divisionId===payload.divisionId);
        division.divisionName = payload.divisionName;
      },
    addDivision(state, payload) {
        state.divisions.push(payload);
      },
    setCurrentDivision(state, payload) {
        state.currentDivision = payload;
      },
};
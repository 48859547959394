export  default {
  setContainers(state, payload) {
    state.containers = payload;
  },
  setContainer(state, payload) {
    state.container = payload;
  },
  setGridContainers(state, payload) {
    state.gridContainers = payload;
  },
  setSelectedContainer(state, payload) {
    state.selectedContainer = payload;
  },
  setContainerHistory(state, payload) {
    state.containerHistory = payload;
  },
  setContainerPropertyHistory(state, payload) {
    state.containerPropertyHistory = payload;
  },
  setContainersStatus(state, payload) {
    state.containersStatus = payload;
  },
  setContainerStatus(state, payload) {
    state.containerStatus = payload;
  },
  setContainerProps(state, payload) {
    state.containerProps = payload;
  },
  updateContainerProperties(state, payload) {
    
  },
  setContainersStatusFilter(state, payload) {
    state.containersStatusFilter = payload;
  },
};
<template>
  <div>
    <!-- TEST {{ containerId }} -->
    
    <DxBox :height="550" direction="row" width="100%" >
      <DxItem :ratio="1">
        <R3DetailsMenu @menu-change="menuSelectionChange"/>
      </DxItem>
      <DxItem :ratio="3">
        <R3DetailsInfo v-if="currMenu==0" :containerId="containerId" :container="container" />
        <R3DetailsSpecifics v-if="currMenu==1" :containerId="containerId" :container="container"/>
      </DxItem>
    </DxBox>
  </div>
  
</template>

<script>

import R3DetailsMenu from "@/components/container/container-details-menu.vue";
import R3DetailsInfo from "@/components/container/container-details-info.vue";
import R3DetailsSpecifics from "@/components/container/container-details-specifics.vue";
import {DxBox, DxItem} from 'devextreme-vue/box';

export default {
  components: {
    R3DetailsMenu,
    R3DetailsInfo,
    R3DetailsSpecifics,
    DxBox,
    DxItem
  },
  created() {  },
  props: ['containerId', 'container'],
  data() {
    return {
      currMenu: 0
    };
  },
  methods: {
    menuSelectionChange(menu){
      this.currMenu = menu;
    }
  },
};

</script>

<style scoped>
  
</style>

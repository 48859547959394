<template>
  <h2 class="content-block">Tank Details</h2>
  
  <div v-if="!currentContainer" class="loading"></div>
  <div v-else>
  
  

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
      <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
      <DxItem
        :options="reportingButtonOptions"
        location="after"
        widget="dxButton"
        hint="History"
      />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <h2>{{currentContainer.containerName}}</h2>
    <div class="pval">Downhole LSD: {{this.getPropertyVal('Downhole LSD')}}</div>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <div>
      <DxSelectBox 
        :data-source="propertyTypes" 
        display-expr="name"
        value-expr="id" 
        :value="currentPropClass" 
        @value-changed="updatePropClass"/>
    </div>
  </div>

  <div class="content-block dx-card responsive-paddings">
        
    <div v-if="(currentPropClass==0)">
      <DxForm id="form" 
          :form-data="currentContainer" 
          :label-mode="outside" 
          :read-only="true" 
          :show-colon-after-label="true"
          :label-location="top" 
          :col-count="1" 
          :min-col-width="300" 
          :width="null"
          :items="['containerName','containerType','area','operator','division', 'serialnum']"
        >
      </DxForm>
    </div>

    <div v-for="prop in currentContainer.properties.filter(x=>x.propertyClassId==currentPropClass)" :key="prop.containerPropId">
      <DxResponsiveBox :screen-by-width="screen" single-column-screen="sm">
        <DxRow :ratio="1"/>
        <DxRow :ratio="1"/>
        <DxRow :ratio="1"/>
        <DxRow :ratio="1"/>

        <DxCol :ratio="1.5"/>  
        <DxCol :ratio="1"/>  
        <DxCol :ratio="1"/>  
        <DxCol :ratio="1"/>  

        <DxItem :ratio="1">
          <template #default>
            <DxLocation :row="0" :col="0" :colspan="1" screen="lg"/>
            <DxLocation :row="0" :col="0" :colspan="1" screen="sm"/>
            <div><h6>{{prop.status}} {{prop.propertyName}} : </h6></div>
          </template>
        </DxItem>
        <DxItem :ratio="1">
          <template #default>
            <DxLocation v-if="prop.propertyType==='NUMBER' && prop.useThresholds" :row="0" :col="1" :colspan="1" screen="lg"/>
            <DxLocation v-else :row="0" :col="1" :colspan="3" screen="lg"/>
            <DxLocation v-if="prop.propertyType==='NUMBER' && prop.useThresholds" :row="1" :col="0" :colspan="3" screen="sm"/>
            <DxLocation v-else :row="1" :col="0" :colspan="1" screen="sm"/>
            <div>
              <DxTextBox class="tb" label="Value" :read-only="propEditable(prop.propertyName)" :value="prop.propValue" @value-changed="(e)=> { this.propValueChanged(prop, 'VALUE', e); }"/>
            </div>
            <!-- <div><DxTextBox class="tb" label="Value" :value="prop.propValue"/></div> -->
          </template>
        </DxItem>
        <DxItem :ratio="1">
          <template #default>
            <DxLocation :row="0" :col="2" :colspan="1" screen="lg"/>
            <DxLocation :row="2" :col="0" :colspan="1" screen="sm"/>
            <div v-if="prop.propertyType==='NUMBER' && prop.useThresholds"><DxTextBox class="tb" label="Marginal Threshold" :value="prop.valueMarginal" @value-changed="(e)=> { this.propValueChanged(prop, 'MARGINAL', e);}"/></div>
          </template>
        </DxItem>
        <DxItem :ratio="1">
          <template #default>
            <DxLocation :row="0" :col="3" :colspan="1" screen="lg"/>
            <DxLocation :row="3" :col="0" :colspan="1" screen="sm"/>
            <div v-if="prop.propertyType==='NUMBER' && prop.useThresholds"><DxTextBox class="tb" label="Critical Threshold" :value="prop.valueCritical" @value-changed="(e)=> { this.propValueChanged(prop, 'CRITICAL', e); }"/></div>
          </template>
        </DxItem>
      <!-- </DxBox> -->
      </DxResponsiveBox>
    </div>
  </div>
</div>
</template>

<script>

import { DxForm, DxItem } from 'devextreme-vue/form';
// , { DxSimpleItem }
import DxToolbar from 'devextreme-vue/toolbar';
import DxTextBox from "devextreme-vue/text-box";
// import notify from 'devextreme/ui/notify';
import { DxResponsiveBox, DxLocation, DxCol, DxRow } from 'devextreme-vue/responsive-box';
// import { DxResponsiveBox,  DxCol, DxRow } from 'devextreme-vue/responsive-box';
// import { DxResponsiveBox } from 'devextreme-vue/responsive-box';
// import DxBox from "devextreme-vue/box";
// import { DxButton } from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    DxResponsiveBox, DxLocation, DxCol, DxRow,
    // DxResponsiveBox, DxCol, DxRow,
    // DxResponsiveBox, 
    DxForm, 
    DxItem,
    // DxSimpleItem, 
    DxToolbar,
    DxTextBox,
    // DxBox,
    // DxButton,
    DxSelectBox,
  
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      screen(width) {
        return (width < 700) ? 'sm' : 'lg';
      },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          // console.log(this.changedProperties);
          this.$store.dispatch('containers/updateContainerProperties', this.changedProperties);
          this.$emit('recordUpdated');
        },
      },
      reportingButtonOptions: {
        icon: 'chart',
        onClick: () => {
          this.$router.push({ name: 'reporting-containers-history-id', params: {id: this.currentRecordId}});
        },
      },
      // currentContainer: [],
      currentProperties: [],
      changedProperties: [],
      propertyTypes:[
        {id: 0, name: "Site Information"},
        {id: 1, name: "Operations"},
        {id: 2, name: "Tank"},
        {id: 3, name: "External"},
        {id: 4, name: "Financial"},
        {id: 5, name: "System"}
      ],
      currentPropClass:1
    };
  },
  computed: {
    currentRecordId() {
      return parseInt(this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)); 
    },
    containers() {
      return this.$store.getters["containers/getContainerStatus"];
    },
    currentContainer(){
      return this.$store.getters["containers/getContainerStatus"];
    }
  },
  methods: {
    propEditable(propName){
      if (propName=="Downhole LSD" || propName=="Injection Target" || propName=="Alias" )
        return false;
      else
        return true;
    },
    propValueChanged(prop, field, data) {
      let changeProp = this.changedProperties.find(x=>x.containerPropId===prop.containerPropId);
      
      if (changeProp==null)
      {
        changeProp={
          containerPropId: prop.containerPropId,
          containerId: this.currentRecordId,
          // containerId: prop.containerId,
          propertyId: prop.propertyId,
          propValue: prop.propValue,
          marginal: prop.valueMarginal,
          critical: prop.valueCritical,
          propertySort: prop.propertySort
        };

        this.changedProperties.push(changeProp);
      }

      if(field=="VALUE")
        { changeProp.propValue = data.value; }
      else if(field=="MARGINAL")
        { changeProp.marginal=data.value; }
      else if(field=="CRITICAL")
        { changeProp.critical=data.value; }
    },
    getPropertyVal(propName){
      let retVal="-";
      if (this.currentContainer.containerName !== undefined)
      {
        // console.log("PROPERTIES",this.currentContainer.properties)
        const p = this.currentContainer.properties.filter((data) => data.propertyName==propName);
        // console.log(propName, p[0].propValue, p[0].propValue.length)
        if ((String(p[0].propValue)).length === undefined || (String(p[0].propValue)).length == 0)
        {
          retVal = '-';
        }
        else{
          retVal = p[0].propValue;
        }
      }
      return retVal;
    },
    updatePropClass(e){
      this.currentPropClass=e.value;
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        console.log ("*****************")
        await this.$store.dispatch("containers/loadContainerStatus", this.currentRecordId );
        console.log ("*****************")
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }   

      // var data = this.containers.find((data) => data.containerId === this.currentRecordId);
      // this.currentContainer = data;       
    },
  },
};
</script>

<style scoped>
.tb{
  margin: 10px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}

</style>

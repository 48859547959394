<template>
  <DxScrollView :width="auto" :height="440" direction="both">  <!-- 'horizontal' | 'vertical' -->
    <div v-if="statusCritical.length>0" class="divGroup critical property" id ="containerInfo" width = 'auto'>
      <!-- Critical Status -->
      <div v-for="prop in statusCritical" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
    <br>
    <div v-if="statusMarginal.length>0" class="divGroup marginal property" id ="containerInfo" width = 'auto'>
      <!-- Marginal Status -->
      <div v-for="prop in statusMarginal" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
    <br>
    <div v-if="statusGood.length>0" class="divGroup good property" id ="containerInfo" width = 'auto'>
      <!-- Good Status -->
      <div v-for="prop in statusGood" :key="prop.containerPropId">
        {{prop.status}} {{prop.propertyName}} : {{prop.propValue}}
      </div>
    </div>
    <!-- <div v-if="statusUndefined.length>0" class="divGroup unknown property" id ="containerInfo" width = 'auto'> -->
      <!-- Undefined Status -->
      <!-- <div v-for="prop in statusUndefined" :key="prop.containerPropId"> -->
        <!-- {{prop.status}} {{prop.propertyName}} : {{prop.propValue}} -->
      <!-- </div> -->
    <!-- </div> -->
  </DxScrollView>
  
</template>

<script>

import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  },
  props: ['containerId', 'container'],
  data() {
    return {
    };
  },
  computed: {
    // ❌⚠️✔️
    statusCritical(){
      // console.log(this.container.properties.filter((data) => data.status == "❌"));
      return this.container.properties.filter((data) => data.status == "❌");
    },
    statusMarginal(){
      return this.container.properties.filter((data) => data.status == "⚠️");
    },
    statusGood(){
      return this.container.properties.filter((data) => data.status == "✔️");
    },
    statusUndefined(){
      return this.container.properties.filter((data) => data.status == "�");
    }
  },
  created() {  },
  methods:{
    
  }
};

</script>

<style scoped>
  .divGroup {
    width: 90%;
    border-style: solid;
    border-width: 1px; 
    /* margin: 20px; */
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .critical {
    border-color: rgb(180, 0, 0);
  }
  .marginal {
    border-color: rgb(180, 150, 0);
  }
  .good {
    border-color: rgb(0, 180, 0);
  }
  .unknown {
    border-color: rgb(100, 100, 100);
  }
  .property {
    font-size: 14px;
    font-weight: 100;
    color: rgb(255,255,255,.8);
    /* margin-left: 10px; */
    margin-bottom: 2px;
    /* z-index: 20; */
  }
</style>

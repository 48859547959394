export default {
  async updateContainerType(context, payload) {

    const newRequest = {
      containerTypeId: payload.containerTypeId,
      containerTypeName: payload.containerTypeName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containerTypes/${payload.containerTypeId}`;

    // var url = `https://r3backendprd.azurewebsites.net/api/containerTypes/${payload.containerTypeId}`;
    
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    //const responseData = await response.json();

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateContainerType', payload);
  },

  async addContainerType(context, payload) {
    const newRequest = {
      containerTypeId: payload.containerTypeId,
      containerTypeName: payload.containerTypeName
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containerTypes`;
    // var url = `https://r3backendprd.azurewebsites.net/api/containerTypes`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    payload.containerTypeId = responseJson.containerTypeId;
    context.commit('addContainerType', payload);
  },

  async loadContainerTypes(context, payload ) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containerTypes`;
    // var url = `https://r3backendprd.azurewebsites.net/api/containerTypes`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const responseJson = await response.json();

    const responseData = [];

    for (const key in responseJson) {
      
      const containerType = {
        containerTypeId: responseJson[key].containerTypeId,
        containerTypeName: responseJson[key].containerTypeName,
        };

      responseData.push(containerType);
        
    }
    context.commit('setContainerTypes', responseData)
  },

  async loadContainerType(context, payload ) {

    const id = payload.id;

    let containerType = {
      containerTypeId: 0,
      containerTypeName: "",
      };

    if (id != 0)
    {
      var token = context.rootGetters['auth/getToken'];
      var bearer_token = "Bearer " + token;
      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/containerTypes/${id}`;  
      // var url = `https://r3backendprd.azurewebsites.net/api/containerTypes/${id}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        //error, NOT ok
        const error = new Error("Failed to fetch.");
        throw error;
      }
  
      const responseJson = await response.json();
  
      containerType = {
        containerTypeId: responseJson.containerTypeId,
        containerTypeName: responseJson.containerTypeName,
        };
    }
    context.commit('setCurrentContainerType', containerType)
  },
};

export  default {
    getDistricts(state) {
        return state.districts;
    },
    getCurrentDistrict(state) {
        return state.currentDistrict;
    },
    getResponseCode(state) {
        return state.responseCode;
    },
};
<template>

  <h2 class="content-block">Containers : Edit</h2>

  <DxToolbar class="menubar">
    <DxItem
      :options="cancelButtonOptions"
      location="before"
      widget="dxButton"
    />
    <DxItem
      :options="deleteButtonOptions"
      location="before"
      widget="dxButton"
    />
    <DxItem
      :options="saveButtonOptions"
      location="before"
      widget="dxButton"
    />
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <h2>{{currentContainer.containerName}}</h2>
  </div>

  <div class="content-block dx-card responsive-paddings">
    
    <DxTextBox class="form-field" label="Id" :read-only="true" :value="currentContainer.containerId" />
    <DxTextBox class="form-field" label="Name" v-model:value="currentContainer.containerName" @value-changed="nameChanged" /> 
    <DxSelectBox class="form-field" label="Container Type Id" :read-only="true" :data-source="this.containerTypes" value-expr="containerTypeId" display-expr="containerTypeName" v-model:value="currentContainer.containerTypeId"/>     
    <DxSelectBox class="form-field" label="Division ID" :read-only="true" :data-source="this.divisions" value-expr="divisionId" display-expr="divisionName" v-model:value="currentContainer.divisionId"/>     
    <DxSelectBox class="form-field" label="Operator Id" :data-source="this.operators" value-expr="operatorId" display-expr="operatorName" v-model:value="currentContainer.operatorId" @value-changed="operatorChanged"/>     
    <DxCheckBox class="form-field" text="View All" :value="allAreas" :enable-three-state-behavior="false" @value-changed="allAreasChanged" />
    <DxSelectBox label="Area Id" v-if="allAreas==true" :data-source="this.areas" value-expr="areaId" display-expr="areaName" v-model:value="currentContainer.areaId"/>     
    <DxSelectBox label="Area Id" v-else :data-source="existingOperatorAreas" value-expr="areaId" display-expr="areaName" v-model:value="currentContainer.areaId"/>     
    <DxTextBox class="form-field" label="Serial Number" :read-only="true" :value="currentContainer.serialnum" />

  </div>
</template>

<script>

import { DxForm, DxItem, DxSimpleItem } from 'devextreme-vue/form';
import DxToolbar from 'devextreme-vue/toolbar';
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from 'devextreme-vue/select-box';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { DxCheckBox } from 'devextreme-vue/check-box';

export default {
  components: {
    DxItem,
    DxToolbar,
    DxTextBox,
    DxSelectBox,
    DxCheckBox
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      deleteButtonOptions: {
        icon: 'close',
        onClick: () => {
          // decommission site
          let result = confirm("<b>Are you sure you want to decommission this tank?</b>" + 
            "<p>Name: " + this.currentContainer.containerName + 
              "<br>Device: " + this.currentContainer.serialnum + 
              "<br>Operator: " + this.currentContainer.operator + 
              "<br>Area: " + this.currentContainer.area + 
              "<p><i>This cannot be restored.</i>", "Decommission?");
          result.then((dialogResult) => {this.decommission(dialogResult);});
        },
      },
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          this.saveContainerInfo();
          this.$emit('recordUpdated');
        },
      },
      currentContainer: null,
      operators: [],
      areas:[],
      containerTypes:[],
      divisions:[],
      allAreas: false,
    };
  },
  computed: {
    currentRecordId() {
      return parseInt(this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1)); 
    },
    existingOperatorAreas(){
      return this.$store.getters["operators/getOperatorsAreas"].filter(x => x.operatorId == this.currentContainer.operatorId);
    }
  },
  methods: {
    nameChanged(e){
      this.currentContainer.containerName = e.value
    },
    operatorChanged(e) {
      this.currentContainer.areaId = null;
    },
    allAreasChanged(e) {
      this.allAreas = e.value;
      this.currentContainer.areaId = 0;
    },
    saveContainerInfo(){
      
      let request={
        // containerId: this.currentContainer.containerId,
        containerId: this.currentRecordId,
        containerTypeId: this.currentContainer.containerTypeId,
        operatorId: this.currentContainer.operatorId,
        divisionId: this.currentContainer.divisionId,
        areaId: this.currentContainer.areaId,
        containerName: String(this.currentContainer.containerName),
        serialnum: String(this.currentContainer.serialnum),
        latitude: 0,
        longitude: 0,
        altitude: 0
      }
      this.$store.dispatch('containers/updateContainer', this.currentContainer);
    },
    popNotification(msg, type){
      //types ['error', 'info', 'success', 'warning']
      const position = 'center';
      const direction = 'down-stack';
      notify(
        {
          message: msg,
          type: type
        },
        { 
          position, 
          direction 
        }
      );
    },
    decommission(confirmed){
      if (!confirmed)
      { 
        this.popNotification('Container decommission cancelled.','warning');
        return; 
      }

      const site = {
        containerName: this.currentContainer.containerName,
        deviceSerial: this.currentContainer.serialnum,
      }

      this.$store.dispatch('utils/decommissionSite', site);
      this.$emit('recordUpdated');
      this.result = this.$store.getters["utils/getDecommissionSiteResults"];
      if (this.result.recordsAffected==-1)
      { this.popNotification("Site decommissioned successfully. " + Date().toLocaleString() + "\n" + this.result.message,'success'); }
      else
      { this.popNotification("ERROR: Site not decommissioned. " + Date().toLocaleString() + "\n" + this.result.message,'error'); }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainer", this.currentRecordId );        
        await this.$store.dispatch("operators/loadOperators");
        await this.$store.dispatch("operators/loadOperatorsAreas");
        await this.$store.dispatch("areas/loadAreas");
        await this.$store.dispatch("containerTypes/loadContainerTypes");
        await this.$store.dispatch("divisions/loadDivisions");        

        this.currentContainer= await this.$store.getters["containers/getContainer"];
        this.operators = await this.$store.getters["operators/getOperators"];
        this.areas = await this.$store.getters["areas/getAreas"];
        this.containerTypes = await this.$store.getters["containerTypes/getContainerTypes"];
        this.divisions = await this.$store.getters["divisions/getDivisions"];

      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }   
    },
  },
};
</script>

<style scoped>
.form-field{
  margin: 30px 0 0 0;
}
.tb{
  margin: 10px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 30px 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}
</style>

import common from '../common.js';

export default {

  async setContainersStatusFilter(context, payload){
    context.commit('setContainersStatusFilter', payload)
  },

  async loadContainers(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'containers')
    const responseData = [];

    for (const key in responseJson) {
      const cont = {
        containerId: responseJson[key].containerId,
        containerTypeId: responseJson[key].containerTypeId,
        operatorId: responseJson[key].operatorId,
        divisionId: responseJson[key].divisionId,
        areaId: responseJson[key].areaId,
        containerName: responseJson[key].containerName,
        serialnum: responseJson[key].serialnum,
        latitude: responseJson[key].latitude,
        longitude: responseJson[key].longitude,
        altitude: responseJson[key].altitude
      };

      responseData.push(cont);
    }
    context.commit('setContainers', responseData)
  },

  async loadContainer(context, payload) {
    // context.commit('setContainer', container)

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload}`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    
    const container = {
      containerId: responseData.containerId,
      containerTypeId: responseData.containerTypeId,
      operatorId: responseData.operatorId,
      divisionId: responseData.divisionId,
      areaId: responseData.areaId,
      containerName: responseData.containerName,
      serialnum: responseData.serialnum,
      latitude: responseData.latitude,
      longitude: responseData.longitude,
      altitude: responseData.altitude,
    };
    
    context.commit('setContainer', container)
  },

  async loadContainerHistory(context, payload) {
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload}/history`;
    //var url = `https://r3backendprd.azurewebsites.net/api/containers/${payload}/history`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    const containerHist = [];

    for (const key in responseData) {
      if (responseData[key].propertyType == "DATE")
      {
        //console.log(prop.propValue);
        responseData[key].propValue = new Date(responseData[key].propValue + ' UTC').toLocaleString("en-CA", {dateStyle: 'short', timeStyle: 'short'});
        //console.log(prop.propValue);                    
      }
      const histEntry = {
        historyId:  responseData[key].historyId,
        containerId: responseData[key].containerId,
        containerName: responseData[key].containerName,
        propertyId: responseData[key].propertyId,
        historyDate: new Date(responseData[key].historyDate),
        propValue: responseData[key].propValue,
        historyType: responseData[key].historyType,
        notes: responseData[key].notes,
        propertyName: responseData[key].propertyName,
      };
      containerHist.push(histEntry);
    }
    context.commit('setContainerHistory', containerHist)
  },
  async resetContainerPropertyHistory(context, payload){
    context.commit('setContainerPropertyHistory', "")
  },
  async loadContainerPropertyHistory(context, payload) {
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload.container}/history/${payload.propertyName}`;
    
    //var url = `https://r3backendprd.azurewebsites.net/api/containers/${payload}/history`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    const containerHist = [];
    
    for (const key in responseData) {
      if (responseData[key].propertyType == "DATE")
      {
        responseData[key].propValue = new Date(responseData[key].propValue + ' UTC').toLocaleString("en-CA", {dateStyle: 'short', timeStyle: 'short'});
      }
      const histEntry = {
        historyId:  responseData[key].historyId,
        containerId: responseData[key].containerId,
        containerName: responseData[key].containerName,
        propertyId: responseData[key].propertyId,
        historyDate: new Date(responseData[key].historyDate),
        propValue: responseData[key].propValue,
        historyType: responseData[key].historyType,
        notes: responseData[key].notes,
        propertyName: responseData[key].propertyName,
      };
      containerHist.push(histEntry);
    }
    context.commit('setContainerPropertyHistory', containerHist)
  },
  async loadContainersGridInfo(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'containers/compact')
    const responseData = [];

    for (const key in responseJson) 
    {
      const container = {
        containerId: responseJson[key].id,
        typeId: responseJson[key].type,
        operatorId: responseJson[key].oper,
        divisionId: responseJson[key].div,
        areaId: responseJson[key].area,
        containerName: responseJson[key].name,
        serialnum: responseJson[key].sn,
        properties: []
      };
      
      for (const propKey in responseJson[key].properties) 
      {
        var containerProperty = {
          propertyId: responseJson[key].properties[propKey].prop,
          propertyValue: responseJson[key].properties[propKey].value,
        }
        container.properties.push(containerProperty);
      }
      
      responseData.push(container);
    }
    context.commit('setGridContainers', responseData)
  },

  async loadContainersStatus(context, payload) {
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/details`;
    // var url = "https://r3backendprd.azurewebsites.net/api/containers/details";
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }
    const gridContainers = [];
    
    for (const key in responseData) {
      var gridContainer = {
        containerId: responseData[key].containerId,
        containerName: responseData[key].containerName,
        containerType: responseData[key].containerType,
        containerTypeId: responseData[key].containerTypeId,
        area: responseData[key].area,
        division: responseData[key].division,
        operator: responseData[key].operator,
        serialnum: responseData[key].serialnum,
        containerStatus: "✔️",
        properties: responseData[key].containerPropActuals,
        
      };
      
      const containerProps = [];
      
      for (const propKey in responseData[key].containerPropActuals) {
      
        var prop = responseData[key].containerPropActuals[propKey];
        let propStatus = "�";

        if (prop.propertyType=="NUMBER")
        {
          prop.propValue = parseFloat(prop.propValue);
          prop.valueMarginal = parseFloat(prop.valueMarginal);
          prop.valueCritical = parseFloat(prop.valueCritical);
          
          if (isNaN(prop.propValue))
          {
            prop.propValue=0;
          }
          else
          {
            prop.propValue = Math.round(prop.propValue*10)/10;
          }
          
          propStatus = "✔️";

          if (isNaN(prop.valueMarginal))
          {
            prop.valueMarginal=null;
          }
          else if (prop.propValue < prop.valueMarginal)
          {
            propStatus = "⚠️";
            gridContainer.containerStatus = (gridContainer.containerStatus=="✔️") ? "⚠️" : gridContainer.containerStatus;
          }

          if (isNaN(prop.valueCritical))
          {
            prop.valueCritical=0;
          }
          else if (prop.propValue < prop.valueCritical)
          {
            propStatus = "❌";
            gridContainer.containerStatus = gridContainer.containerStatus="❌";
          }
        }

        if (prop.propertyType=="DATE")
        {
          //console.log(prop.propValue);
          prop.propValue = new Date(prop.propValue + ' UTC').toLocaleString("en-CA", {dateStyle: 'short', timeStyle: 'short'});
          //console.log(prop.propValue);                    
        }

        var containerProp = {
          containerPropId: prop.containerPropId,
          propertyId: prop.propertyId,
          propertyName: prop.propertyName,
          propertyType: prop.propertyType,
          propertyClassId: prop.propertyClassId,
          propValue: prop.propValue,
          valueMarginal: prop.valueMarginal,
          valueCritical: prop.valueCritical,
          propertyImgName: `img/${prop.propertyImgName}`,
          propertySort: prop.propertySort,
          status: propStatus,
          useThresholds: !!prop.useThresholds,
          uiShowList: prop.uiShowList
        }
        containerProps.push(containerProp);
      }
      gridContainer.properties=containerProps;
      gridContainers.push(gridContainer);
    }
    //console.log("LOADED CONTAINERS (Action)",gridContainers)
    context.commit('setContainersStatus', gridContainers)
  },

  async loadContainerStatus(context, payload) {
    
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload}/details`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }

    var gridContainer = {
      containerId: responseData.containerId,
      containerName: responseData.containerName,
      containerType: responseData.containerType,
      area: responseData.area,
      division: responseData.division,
      operator: responseData.operator,
      serialnum: responseData.serialnum,
      containerStatus: "✔️",
      properties: responseData.containerPropActuals,      
    };
      
    const containerProps = [];
      
    for (const propKey in responseData.containerPropActuals) {
      var prop = responseData.containerPropActuals[propKey];
      let propStatus = "�";

      if (prop.propertyType=="NUMBER")
      {
          prop.propValue = parseFloat(prop.propValue);
          prop.valueMarginal = parseFloat(prop.valueMarginal);
          prop.valueCritical = parseFloat(prop.valueCritical);
          
          if (isNaN(prop.propValue))
          {
            prop.propValue=0;
          }
          else
          {
            prop.propValue = Math.round(prop.propValue*10)/10;
          }
          
          propStatus = "✔️";

          if (isNaN(prop.valueMarginal))
          {
            prop.valueMarginal=null;
          }
          else if (prop.propValue < prop.valueMarginal)
          {
            propStatus = "⚠️";
            gridContainer.containerStatus = (gridContainer.containerStatus=="✔️") ? "⚠️" : gridContainer.containerStatus;
          }

          if (isNaN(prop.valueCritical))
          {
            prop.valueCritical=null;
          }
          else if (prop.propValue < prop.valueCritical)
          {
            propStatus = "❌";
            gridContainer.containerStatus = gridContainer.containerStatus="❌";
          }
      }
      if (prop.propertyType=="DATE")
      {
        //console.log(prop.propValue);
        prop.propValue = new Date(prop.propValue + ' UTC').toLocaleString("en-CA", {dateStyle: 'short', timeStyle: 'short'});
        //console.log(prop.propValue);                    
      }
      var containerProp = {
        containerPropId: prop.containerPropId,
        propertyId: prop.propertyId,
        propertyName: prop.propertyName,
        propertyType: prop.propertyType,
        propertyClassId: prop.propertyClassId,
        propValue: prop.propValue,
        valueMarginal: prop.valueMarginal,
        valueCritical: prop.valueCritical,
        propertyImgName: `img/${prop.propertyImgName}`,
        propertySort: prop.propertySort,
        status: propStatus,
        useThresholds: !!prop.useThresholds,
        uiShowList: prop.uiShowList
      }
      containerProps.push(containerProp);
    }
    gridContainer.properties = containerProps;
    context.commit('setContainerStatus', gridContainer)

  },
  async loadContainerProperties(context, payload){

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload}/properties`;
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });
    
    if (!response.ok) {
      //error, NOT ok
      const error = new Error(responseData.message || "Failed to fetch.");
      throw error;
    }

    const responseData = await response.json();
    const propData = [];
      
    for (const key in responseData) {
      if (responseData[key].propertyType == "DATE")
      {
        //console.log(prop.propValue);
        responseData[key].propValue = new Date(responseData[key].propValue + ' UTC').toLocaleString("en-CA", {dateStyle: 'short', timeStyle: 'short'});
        //console.log(prop.propValue);                    
      }
      var containerProp = {
        containerPropId: responseData[key].containerPropId,
        propertyId: responseData[key].propertyId,
        propertyName: responseData[key].propertyName,
        propertyType: responseData[key].propertyType,
        propValue: responseData[key].propValue,
        valueMarginal: responseData[key].valueMarginal,
        valueCritical: responseData[key].valueCritical,
        propertyImgName: `img/${responseData[key].propertyImgName}`,
        propertySort: responseData[key].propertySort,
      }
      propData.push(containerProp);
    }
    // console.log(payload);
    // console.log(url);
    // console.log(propData);
    
    context.commit('setContainerProps', propData)
  },
  async updateContainerProperties(context, payload) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    
    for(let i = 0;i < payload.length;i++){
     
      const newRequest = {
        containerPropId: payload[i].containerPropId,
        containerId: payload[i].containerId,
        propertyId: payload[i].propertyId,
        propValue: String(payload[i].propValue),
        valueMarginal: String(payload[i].marginal),
        valueCritical: String(payload[i].critical),
        propertySort: payload[i].propertySort
      };

      var apiUrl = context.rootGetters['config/getApiUrl']
      var url = `${apiUrl}/containerproperties/${payload[i].containerPropId}`;

      // var url = `https://r3backendprd.azurewebsites.net/api/containerproperties/${payload[i].containerPropId}`;
      
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: bearer_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRequest)
      });

      if (!response.ok) {
        const error = new Error('Failed to send request.');
        throw error;
      }

      context.commit('updateContainerProperties', payload);
    }
  },
  async updateContainerProperty(context, payload) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;

    const newRequest = {
      containerPropId: payload.containerPropId,
      containerId: payload.containerId,
      propertyId: payload.propertyId,
      propValue: String(payload.propValue),
      valueMarginal: String(payload.marginal),
      valueCritical: String(payload.critical),
      propertySort: payload.propertySort
    };

    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containerproperties/${payload.containerPropId}`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    })

    if (!response.ok) {
      const error = new Error('Failed to send request.');
      throw error;
    }

    context.commit('updateContainerProperties', payload);
    
  },
  async updateContainer(context, payload) {

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/containers/${payload.containerId}`;

    const newRequest = {
      containerId: payload.containerId,
      containerTypeId: payload.containerTypeId,
      operatorId: payload.operatorId,
      divisionId: payload.divisionId,
      areaId: payload.areaId,
      containerName: String(payload.containerName),
      serialnum: String(payload.serialnum),
      latitude: 0,
      longitude: 0,
      altitude: 0
    };

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    })

    if (!response.ok) {
      const error = new Error('Failed to send request.');
      throw error;
    }

    // context.commit('updateContainerProperties', payload);
    
  },
};

<template>
  <R3Title title="Reporting ❯ Bulk Tank Status"></R3Title> <!-- unicode U+276F : ❯ -->
  
  <div>
    <R3ContainerGrid typeId="2" @select-container="openDetails" />
    <DxPopup
      v-model:visible="detailsVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="800"
      :height="600"
      :title="fullContainer.containerName"
      content-template="tank-info"
    >
      <template #tank-info>
        <R3ContainerDetails :containerId="selectedContainer" :container="fullContainer" />
      </template> 
    </DxPopup>
  </div>
  
</template>

<script>

import R3Title from "@/components/base/page-title.vue";
import R3ContainerGrid from "@/components/container/container-grid.vue";
import R3ContainerDetails from "@/components/container/container-details.vue";
import { DxPopup } from 'devextreme-vue/popup';

export default {
  components: {
    R3Title,
    R3ContainerGrid,
    R3ContainerDetails,
    DxPopup,
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      selectedContainer: 0,
      fullContainer: 0,
      detailsVisible: false
    };
  },
  computed: { },
  methods: {
    openDetails(key, container) {
      // alert("OPEN: " + key);
      this.selectedContainer = key;
      this.fullContainer = container;
      this.detailsVisible = true;
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    async loadPageData() {
      
    },
  },
};

</script>

<style scoped>
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
</style>

export  default {
    setOperators(state, payload) {
        state.operators = payload;
      },
    updateOperator(state, payload) {
        const operator = state.operators.find(x=>x.operatorId===payload.operatorId);
        operator.operatorName = payload.operatorName;
      },
    addOperator(state, payload) {
        state.operators.push(payload);
      },
    setCurrentOperator(state, payload) {
        state.currentOperator = payload;
      },
    setOperatorsAreas(state, payload) {
        state.operatorsAreas = payload;
      },
};
<template>
  <h2 class="content-block">Device Status Details</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>
  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="deviceStatuses" 
        :label-mode="outside" 
        :read-only="false" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
      >
      <DxSimpleItem data-field="deviceStatusId"  :editor-options="{readOnly: true}" />
      <DxSimpleItem data-field="statusName" />

      <!-- TEST is this CAN be a simple one-liner with e onValueChanged method to alter underlying data-field -->

      <DxSimpleItem>
        <template #default>
          <!-- this doesnt save the data for some reason, but is inline with the rest of the form data -->
          <DxCheckBox rtlEnabled="true" text= " :Default" :value="deviceStatuses.defaultDeviceStatus" />
        </template>  
      </DxSimpleItem>

    </DxForm>
    <!-- this format DOES save the data but its no inline with the Form -->
    <!-- <DxCheckBox class = "formitem" icon-size="25" v-model:value="deviceStatuses.defaultDeviceStatus"/> -->
  </div>
</template>

<script>

import DxForm, { DxSimpleItem, DxLabel} from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxCheckBox from 'devextreme-vue/check-box'
import notify from 'devextreme/ui/notify';

export default {
  components: {
    DxForm, 
    DxSimpleItem, 
    DxToolbar,
    DxItem,
    DxCheckBox,
    // DxLabel
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          //this.refreshData();
          this.$router.go(-1);
        },
      },
      saveButtonOptions: {
        icon: 'save',
        onClick: () => {
          if (this.deviceStatuses.deviceStatusId==0)
          {
            this.$store.dispatch('deviceStatuses/addDeviceStatus', this.deviceStatuses);
            this.$emit('recordUpdated');
          }
          else
          {
            this.$store.dispatch('deviceStatuses/updateDeviceStatus', this.deviceStatuses);
            this.$emit('recordUpdated');
          }
        },
      },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    deviceStatuses() {
      return this.$store.getters["deviceStatuses/getCurrentDeviceStatus"];
    },
  },
  methods: {
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      
      try {
        await this.$store.dispatch("deviceStatuses/loadDeviceStatus", { id: this.currentRecordId });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
      
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.formitem{
  margin: 30px 0 0 0;
}
</style>

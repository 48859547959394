export  default {
  updateUserPw(state, payload) {
      //do nothing, called the api  
    },
  setProfile(state, payload) {
      state.profile = payload;
    },
  setUsers(state, payload) {
      state.users = payload;
    },
  setUser(state, payload) {
      state.user = payload;
    },
  setUserGroups(state, payload) {
      state.userGroups = payload;
    },
  addUser(state, payload) {
      state.users.push(payload);
    },
  addUserGroup(state, payload) {
      state.userGroups.push(payload);
    },
  setResponseCode(state, payload) {
      state.responseCode = payload;
    }
};
<template>
  <div class="ph">
    <h2 class="content-block">Reporting ❯ Tank Status</h2> <!-- unicode U+276F : ❯ -->
    <DxLoadPanel
        :hide-on-outside-click="true"
        v-model:visible="isLoadPanelVisible"
        message="Loading..."
    />
  </div>

  <div>
    
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="containers"
      :key-expr="containerId"
      :hover-state-enabled="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :columns-auto-width="false"
      :column-min-width="50"
      :allow-column-resizing="true"
      :column-resizing-mode="currentMode"
      :allow-column-reordering="true"
      no-data-text="Loading ..."
      @selection-changed="masterSelectionChanged"
      @row-click="dataRowToggle"
      @initialized="saveGridInstance"
      @content-ready="dataReady"
      :style="{ 'max-height': datagridHeight }"
    >
      <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="rep_status_container_grid"
        />

      <DxToolbar>
        <DxItem location="before" template="filterStatusCriticalTemplate" />
        <DxItem location="before" template="filterStatusMarginalTemplate" />
        <DxItem location="before" template="filterStatusGoodTemplate" />
        <DxItem location="before" template="filterStatusAllTemplate" />
        <DxItem location="after"> <div class="mininote">Rows: {{ rowCount }} </div></DxItem>
        
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>

      <template #filterStatusCriticalTemplate>
        <DxButton text="❌ " hint="Filter Critical Values" @click="filterToggle('❌')" />
      </template>
      <template #filterStatusMarginalTemplate>
        <DxButton text="⚠️ " hint="Filter Marginal Values" @click="filterToggle('⚠️')" />
      </template>
      <template #filterStatusGoodTemplate>
        <DxButton text="✔️ " hint="Filter Good Values" @click="filterToggle('✔️')" />
      </template>
      <template #filterStatusAllTemplate>
        <DxButton text="All" hint="All Statuses" @click="filterToggle('')" />
      </template>
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" hint="Refresh Data" @click="refreshData" />
      </template>
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>
      <template #rowCountTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>

      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" />     <!-- mode="virtual" -->
      <DxColumnFixing :enabled="true" />
      <DxPager
        :show-info="true"
        :show-page-size-selector="true"
        :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true"
      />
      
      <DxColumn id = "stat" data-field="containerStatus" fixed="true" caption="" width="55px" alignment="left" :allow-resizing="false" :allow-filtering="true" :show-in-column-chooser="false" :allow-reordering="false"/>            
      <DxColumn data-field="operator" caption="Operator" min-width="125"/> 
      <DxColumn data-field="area" caption="Area" min-width="125"/>
      <!-- <DxColumn data-field="division" caption="Division" :visible="false"/> -->
      <!-- <DxColumn data-field="containerType" caption="Type" :visible="false"/> -->
      <DxColumn data-field="serialnum" caption="Device" :visible="false" min-width="125"/>
      <DxColumn data-field="containerName" caption="Surface" :allow-reordering="true" min-width="150"/>

      <!-- <DxColumn v-if="columnExists('Downhole LSD')" data-field="Downhole LSD" caption="Downhole LSD" alignment='left' min-width="150" :visible="false"/>    -->
      <!-- <DxColumn v-if="columnExists('Alias')" data-field="Alias" caption="Alias" alignment='left' min-width="100" :visible="true"/> -->
      <!-- <DxColumn v-if="columnExists('Capacity')" data-field="Capacity" caption="Capacity" alignment='left' min-width="100"/>   -->
      <!-- <DxColumn v-if="columnExists('Volume (L)')" data-field="Volume (L)" caption="Volume (L)" alignment='left' min-width="100"/>   -->
      <!-- <DxColumn v-if="columnExists('Volume (%)')" data-field="Volume (%)" caption="Volume (%)" alignment='left' min-width="100"/>   -->
      <!-- <DxColumn v-if="columnExists('Volume (cm)')" data-field="Volume (cm)" caption="Volume (cm)" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Injection')" data-field="Injection" caption="Injection" alignment='left' min-width="100"/> -->
      <!-- <DxColumn v-if="columnExists('Target Injection')" data-field="Target Injection" caption="Target Injection" alignment='left' :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('DTE')" data-field="DTE" caption="DTE" alignment='left' min-width="100"/>    -->
      <!-- <DxColumn v-if="columnExists('Product')" data-field="Product" caption="Product" alignment='left' min-width="100" :visible="false"/>    -->
      <!-- <DxColumn v-if="columnExists('Heel Inventory')" data-field="Heel Inventory" caption="Heel Inventory" alignment='left' min-width="100" :visible="false"/>    -->
      <!-- <DxColumn v-if="columnExists('Last Communication')" data-field="Last Communication" caption="Last Communication" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Temperature')" data-field="Temperature" caption="Temperature" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Satellite')" data-field="Satellite" caption="Satellite" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Consumption')" data-field="Consumption" caption="Satellite" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Consumption Target')" data-field="Target" caption="Satellite" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Satellite Connection')" data-field="Satellite Connection" caption="Satellite" alignment='left' min-width="100" :visible="false"/> -->
      <!-- <DxColumn v-if="columnExists('Injection Target')" data-field="Injection Target" caption="Target Injection" alignment='left' min-width="100" :visible="true"/> -->
      
      <DxColumn data-field="Downhole LSD" caption="Downhole LSD" alignment='left' min-width="150" :visible="false"/>   
      <DxColumn data-field="Alias" caption="Alias" alignment='left' min-width="100" :visible="true"/>
      <DxColumn data-field="Capacity" caption="Capacity" alignment='left' min-width="100"/>  
      <DxColumn data-field="Volume (L)" caption="Volume (L)" alignment='left' min-width="100"/>  
      <DxColumn data-field="Volume (%)" caption="Volume (%)" alignment='left' min-width="100"/>  
      <DxColumn data-field="Volume (cm)" caption="Volume (cm)" alignment='left' min-width="100" :visible="false"/>
      <DxColumn data-field="Injection Avg" caption="Injection" alignment='left' min-width="100"/>
      <DxColumn data-field="Injection Target" caption="Target Injection" alignment='left' :visible="false"/>
      <DxColumn data-field="DTE" caption="DTE" alignment='left' min-width="100"/>   
      <DxColumn data-field="Product" caption="Product" alignment='left' min-width="100" :visible="false"/>   
      <DxColumn data-field="Heel Inventory" caption="Heel Inventory" alignment='left' min-width="100" :visible="false"/>   
      <DxColumn data-field="Last Device Communication" caption="Last Communication" alignment='left' min-width="100" :visible="false"/>
      <DxColumn data-field="Temperature" caption="Temperature" alignment='left' min-width="100" :visible="false"/>
      <DxColumn data-field="Satellite Connection" caption="Satellite" alignment='left' min-width="100" :visible="false"/>
      
      <!-- <DxColumn data-field="SOMEFIELD" caption="SOMEFIELD" alignment='left' min-width="100" :visible="true"/> -->
      
      <DxMasterDetail
        :enabled="false"
        template="detailTemplate"
      />

      <template #detailTemplate>
        <DxBox direction="row" width="100%">
          <DxItem :ratio="0" :base-size="40">
            <DxButton icon="detailslayout" @click="gotoDetail" hint="Details"/>
            <DxButton icon="chart" @click="gotoHistory" hint="History"/>
          </DxItem>
          <DxItem :ratio="1">
            <DxTextBox class="prop" :label="currentProperties[0].propertyName" :value="currentProperties[0].status + ' ' + currentProperties[0].propValue" :readOnly="true"/>
            <DxTextBox class="prop" :label="currentProperties[5].propertyName" :value="currentProperties[5].status + ' ' + currentProperties[5].propValue" :readOnly="true"/>
          </DxItem>
          <DxItem :ratio="1">
            <DxTextBox class="prop" :label="currentProperties[1].propertyName" :value="currentProperties[1].status + ' ' + currentProperties[1].propValue" :readOnly="true"/>
            <DxTextBox class="prop" :label="currentProperties[2].propertyName" :value="currentProperties[2].status + ' ' + currentProperties[2].propValue" :readOnly="true"/>
          </DxItem>
        </DxBox>
      </template>
    </DxDataGrid>
  </div> <!-- end desktop vers -->
  
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar,
  DxItem,
  DxStateStoring,
  DxMasterDetail,
  DxPaging,
  DxPager,
  DxColumnFixing
} from "devextreme-vue/data-grid";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxButton } from 'devextreme-vue/button';
import DxBox from 'devextreme-vue/box';
import DxTextBox from "devextreme-vue/text-box";
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxLoadPanel,
    DxToolbar,
    DxItem,
    DxButton,
    DxStateStoring,
    DxMasterDetail,
    DxBox, 
    DxTextBox,
    // DxPaging,
    DxPager,
    DxColumnFixing
  },
  created() {
    this.loadPageData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  data() {
    return {
      currentMode: 'widget',
      dataGridInstance: null,
      currentContainer: null,
      currentProperties: null,
      loadingVisible: false,
      isLoadPanelVisible: false,
      datagridHeight: '40vh',
      rowCount: 0,
    };
  },
  computed: {
    containers() {
      // return this.$store.getters["containers/getContainersStatus"];
      return this.$store.getters["containers/getContainersFlatProperties"];
    },
    properties(index){
      return this.containers[index].properties;
    },
    containerProperties(id){
      var data = this.containers.find((data) => data.containerId === this.id).properties;
      return data;
    },
    // rowCount(){
    //   let rec = 0;
    //   if (this.dataGridInstance != null){
    //     rec = this.dataGridInstance.totalCount();
    //     console.log("REC", rec);  
    //   }
      
    //   return rec;//this.dataGridInstance.totalCount();
    // },
  },
  methods: {
    columnExists(argCol){
      //console.log(argCol, !(this.containers[0][argCol]===undefined))
      if (Array.isArray(this.containers)) 
      {
        // return false;
        // return !(this.containers[0][argCol]===undefined);
        return !(this.containers[0][argCol]==undefined);
      }
      else
      {
        return false;
      }
    },
    dataReady(){
      this.rowCount = this.dataGridInstance.totalCount();
      // return this.dataGridInstance.totalCount();
    },
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-215) + 'px';
    },
    changeResizingMode(data) {
      this.currentMode = data.value;
    },
    saveGridInstance: function(e) {
      this.dataGridInstance = e.component;
      this.dataGridInstance.columnOption(0, 'allowHeaderFiltering', false);
    },
    removeFilter(){
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },
    refreshData(){
      //console.log(this.dataGridInstance.totalCount());
      //this.loadPageData();
    },
    async filterToggle(filterArg)
    {
      this.dataGridInstance.columnOption(0, 'filterValue', filterArg);
    },
    dataRowToggle(e){
      var key = this.dataGridInstance.getSelectedRowKeys()[0];
  
      if (e.component.isRowExpanded(key)) {  
          e.component.collapseRow(key);  
      }  
      else {  
          e.component.expandRow(key);  
      }  
    },
    masterSelectionChanged(e) {
      e.component.collapseAll(-1);
      this.currentContainer = e.selectedRowsData[0].containerId;
      this.currentProperties = e.selectedRowsData[0].properties;
    },
    gotoDetail() {
      this.$router.push({ name: 'reporting-containers-status-details', params: {id: this.currentContainer}});
    },
    gotoHistory() {
      this.$router.push({ name: 'reporting-containers-history-id', params: {id: this.currentContainer}});
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("containers/loadContainersStatus", {forceRefresh: refresh});
        console.log("DATA STATUS");
        console.log(this.containers);
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }  
    },
  },
};

</script>

<style scoped>
  .ph { /* page header */
    height: 50px;
  } 
  img {
    width: 80px;  
    height: 80px;
  } 
  p {
    text-align: left;  
    margin-left:4em; 
    margin-top:0em;
    font-weight: 300;
  }
  h6 {
    text-align: left;  
    font-size: 16px;
    margin-top:8px;
    margin-bottom:0em;
    margin-left:2.5em; 
    font-weight: 300;
  }
  h4 {
    font-size: 26px;
    font-weight: 200;
    text-align: left; 
    margin-top:0.5em;
    margin-bottom:0em;
    margin-left:1em; 
  }
  .prop{
    margin: 10px 60px 0 60px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  .mininote {
    text-align: left;  
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
</style>

<template>
  <h2 class="content-block">User Profile</h2>

  <DxToolbar class="menubar">
    <!-- <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
    <DxItem
        :options="saveButtonOptions"
        location="before"
        widget="dxButton"
      /> -->
  </DxToolbar>

  <div class="content-block dx-card responsive-paddings">
    <DxForm id="form" 
        :form-data="user" 
        :label-mode="outside" 
        :read-only="true" 
        :show-colon-after-label="true"
        :label-location="top" 
        :col-count="1" 
        :min-col-width="300" 
        :width="null"
        :items="['userId', 'userName', 'firstName', 'surname']"
      >
      <!-- :items="['containerTypeId', 'containerTypeName']" -->
      <!-- <DxSimpleItem data-field="containerTypeId"  :editor-options="{readOnly: true}" /> -->
      <!-- <DxSimpleItem data-field="containerTypeName" /> -->
      
    </DxForm>
    
  </div>
  <div class="content-block dx-card responsive-paddings" id="pwreset">
    
    <DxTextBox class="tb" label="Enter New Password" mode="password" :show-clear-button="true" v-model:value="resetPassword1" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>
    <DxTextBox class="tb" label="Renter Password" mode="password" :show-clear-button="true" v-model:value="resetPassword2" :input-attr="inputAttr">
      <DxValidator name="password">
        <DxStringLengthRule min="6" />
      </DxValidator>
    </DxTextBox>

    <DxButton text="Update Password" @click="updatePassword()"/>
  </div>
  <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMessage"
      v-model:type="toastType"
      :position="toastPosition"
    />
</template>

<script>

import DxForm, { DxSimpleItem } from "devextreme-vue/form";
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import notify from 'devextreme/ui/notify';
import DxTextBox from "devextreme-vue/text-box";
import { DxButton } from 'devextreme-vue/button';
import {
    DxValidator,
    DxStringLengthRule
} from 'devextreme-vue/validator';
import { DxToast } from 'devextreme-vue/toast';

export default {
  components: {
    DxForm, 
    // DxSimpleItem, 
    DxToolbar,
    // DxItem,
    DxTextBox,
    DxButton,
    DxValidator,
    DxStringLengthRule,
    DxToast,
  },
  created() {
    this.loadPageData();
  },
  data() {
    return {
      toastVisible: false,
      toastMessage: '',
      toastType: 'info',
      toastPosition: {
          at: "bottom",
          my: "top",
          of: "#pwreset"},
      resetPassword1: "",
      resetPassword2: "",
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      inputAttr: {
        mode: "password",
        inputAttr: {
          autocomplete: "off" //"new-password"
        }
      },
      // saveButtonOptions: {
      //   icon: 'save',
      //   onClick: () => {
      //     if (this.containerTypes.containerTypeId==0)
      //     {
      //       this.$store.dispatch('containerTypes/addContainerType', this.containerTypes);
      //       this.$emit('recordUpdated');
      //     }
      //     else
      //     {
      //       this.$store.dispatch('containerTypes/updateContainerType', this.containerTypes);
      //       this.$emit('recordUpdated');
      //     }
      //   },
      // },
    };
  },
  computed: {
    currentRecordId() {
      return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1); 
    },
    user() {
      return this.$store.getters["users/getProfile"];
    },
  },
  methods: {
    updatePassword(){
      if (this.resetPassword1 === this.resetPassword2 && this.resetPassword1.length>=6)
      {
        this.$store.dispatch('users/updateProfile',  { pw: this.resetPassword1 });
        this.toastType = 'success';
        this.toastMessage = 'Password successfully reset.';
        this.toastVisible = true;
      }
      else
      {
        this.toastType = 'error';
        this.toastMessage = 'Error resetting password.';
        this.toastVisible = true;
      }
    },
    refreshData(){
      this.loadPageData();
    },
    async loadPageData(refresh = false) {
      try {
        await this.$store.dispatch("users/loadProfile", { refresh: true });
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
     
    },
  },
};
</script>

<style scoped>
.menubar{
  padding: 0 60px 0 60px ;
}
.tb{
  margin: 10px 0 30px 0px;
}
</style>

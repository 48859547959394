export default {
  
  async loadContainerOperAreas(context, payload ) 
  {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissioncontainergroups/operareas`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const error = new Error("Failed to fetch.");
      throw error;
    }
    const responseJson = await response.json();
    // const responseJson = await this.callAPIGet(context, 'permissioncontainergroups/operareas');

    const data = [];
    
    for (const key in responseJson) {
      
      const existOperArea = {
        operatorId: responseJson[key].operatorId,
        areaId: responseJson[key].areaId,
        };

      data.push(existOperArea);
    }
    context.commit('setContainerOperAreas', data)
  },

  async loadPermContainerGroups(context, payload ) 
  {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissioncontainergroups`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const error = new Error("Failed to fetch.");
      throw error;
    }
    const responseJson = await response.json();
    // const responseJson = await this.callAPIGet(context, 'permissioncontainergroups');
    const data = [];

    for (const key in responseJson) {
      
      const permarea = {
        permId: responseJson[key].permId,
        groupId: responseJson[key].groupId,
        operatorId: responseJson[key].operatorId,
        areaId: responseJson[key].areaId,
        };

      data.push(permarea);
    }
    context.commit('setPermContainerGroups', data)
  },

  async loadPermContainerGroup(context, payload ) 
  {
    const id = payload.id;

    let perm = {
      permId: 0,
      groupId: 0,
      areaId: 0,
      operatorId: 0,
    };

    if (id != 0)
    {
      const responseJson = await this.callAPIGet(context, `permissioncontainergroups/${id}`);
      // var token = context.rootGetters['auth/getToken'];
      // var bearer_token = "Bearer " + token;
      // var apiUrl = context.rootGetters['config/getApiUrl']
      // var url = `${apiUrl}/permissioncontainergroups/${id}`;
  
      // const response = await fetch(url, {
      //   method: "GET",
      //   headers: {
      //     Authorization: bearer_token,
      //     "Content-Type": "application/json",
      //   },
      // });
  
      // if (!response.ok) {
      //   //error, NOT ok
      //   const error = new Error("Failed to fetch.");
      //   throw error;
      // }
  
      // const responseJson = await response.json();
  
      perm = {
        permId: responseJson.permId,
        groupId: responseJson.groupId,
        areaId: responseJson.areaId,
        operatorId: responseJson.operatorId,
        };
    }
    context.commit('setCurrentPerm', perm)
  },

  async addPermission(context, payload) {
    const newRequest = {
      permId: 0,
      groupId: payload.groupId,
      operatorId: payload.operatorId,
      areaId: payload.areaId
    };

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissioncontainergroups`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest)
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    const responseJson = await response.json();

    // payload.areaId = responseJson.areaId;
    // context.commit('addArea', payload);
  },
  async deletePermission(context, payload) {
    const permId = payload;

    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/permissioncontainergroups/${permId}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      //const error = new Error(responseData.message || 'Failed to send request.');
      const error = new Error('Failed to send request.');
      throw error;
    }

    // const responseJson = await response.json();

    // payload.areaId = responseJson.areaId;
    // context.commit('addArea', payload);
  },
};

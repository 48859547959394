import common from '../common.js';

export default {
  async loadLogRequests(context, payload ) 
  {
    const responseJson = await common.getRequest(context, payload, 'logrequests')
    const responseData = [];
    
    for (const key in responseJson) 
    {
      const log = {
        logId: responseJson[key].logRequestId,
        // logDate: responseJson[key].logDate.substring(0,10),
        logDate: responseJson[key].logDate,
        httpMethod: responseJson[key].httpMethod,
        httpPath: responseJson[key].httpPath,
        remoteIp: responseJson[key].remoteIp,
        userId: responseJson[key].userId,
        userName: responseJson[key].userName,
        
      };
      responseData.push(log);        
    }
    context.commit('setLogRequests', responseData)
  },

};


import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home/home-page";
import Profile from "./views/profile/profile-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

import Login from './views/auth/login-form.vue';
import ResetPassword from './views/auth/reset-password.vue';
import RequestPassword from './views/auth/request-password.vue';
import ReportingActiveDevice from './views/reporting/active-device/active-device.vue';
import ReportingContainerHistory from './views/reporting/container-history/container-list.vue';
import ReportingContainerHistoryDetail from './views/reporting/container-history/container-history-single.vue';
import ReportingContainerStatus from './views/reporting/container-status/container-list.vue';
import ReportingContainerStatusBulk from './views/reporting/container-status/container-list-desktop-bulk.vue';
import ReportingContainerCompact from './views/reporting/container-status/container-list-compact.vue';
import ReportingContainerAllInOne from './views/reporting/container-all-in-one/container-all-in-one.vue';
import ReportingContainerMobile from './views/reporting/container-all-in-one/container-all-in-one.vue';
import ReportingContainerStatusDetail from './views/reporting/container-status/container-single.vue';
import ReportingContainerUsage from './views/reporting/container-usage/container-usage.vue';
import MasterDataArea from './views/masterdata/areas/area-list.vue';
import MasterDataAreaDetail from './views/masterdata/areas/area-single.vue';
import MasterDataContainer from './views/masterdata/containers/container-list.vue';
import MasterDataContainerAdd from './views/masterdata/containers/container-add.vue';
import MasterDataContainerDetail from './views/masterdata/containers/container-single.vue';
import MasterDataDivision from './views/masterdata/divisions/division-list.vue';
import MasterDataDivisionDetail from './views/masterdata/divisions/division-single.vue';
import MasterDataGroup from './views/masterdata/groups/groups-list.vue';
import MasterDataGroupDetail from './views/masterdata/groups/group-info.vue';
import MasterDataManufacturer from './views/masterdata/manufacturers/manufacturer-list.vue';
import MasterDataManufacturerDetail from './views/masterdata/manufacturers/manufacturer-single.vue';
import MasterDataContainerType from './views/masterdata/containerTypes/containerType-list.vue';
import MasterDataContainerTypeDetail from './views/masterdata/containerTypes/containerType-single.vue';
import MasterDataOperator from './views/masterdata/operators/operator-list.vue';
import MasterDataOperatorDetail from './views/masterdata/operators/operator-single.vue';
import MasterDataPropertyList from './views/masterdata/propertyLists/property-list-list.vue';
import MasterDataPropertyListDetail from './views/masterdata/propertyLists/property-list-single.vue';
import MasterDataDeviceType from './views/masterdata/deviceTypes/deviceType-list.vue';
import MasterDataDeviceTypeDetail from './views/masterdata/deviceTypes/deviceType-single.vue';
import MasterDataDeviceStatus from './views/masterdata/deviceStatuses/deviceStatus-list.vue';
import MasterDataDeviceStatusDetail from './views/masterdata/deviceStatuses/deviceStatus-single.vue';
import MasterDataUser from './views/masterdata/users/user-list.vue';
import MasterDataUserDetail from './views/masterdata/users/user-single.vue';
import MasterDataUserGroupMembership from './views/masterdata/users/user-group-membership.vue';
import MasterDataSiteDecomm from './views/masterdata/sites/site_decommission.vue';
import MasterDataSiteAdmin from './views/masterdata/sites/site_admin.vue';
import MasterDataOffice from './views/masterdata/offices/office-list.vue';
import MasterDataOfficeDetail from './views/masterdata/offices/office-single.vue';
import MasterDataDistrict from './views/masterdata/districts/district-list.vue';
import MasterDataDistrictDetail from './views/masterdata/districts/district-single.vue';
import LogInjection from './views/logs/log-injection/log-injection.vue';
import LogRequest from './views/logs/log-request/log-request.vue';

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/",
      redirect: "/reporting/containers-status"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/reporting/containers-status"
    },
    // {
    //   path: "/containers",
    //   name: "containers",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: ReportingContainerStatus
    // },
    {
      path: "/reporting/active-devices",
      name: "reporting-active-devices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingActiveDevice
    },
    {
      path: "/reporting/containers-status",
      name: "reporting-containers-status",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerStatus
    },
    {
      path: "/reporting/containers-status-bulk",
      name: "reporting-containers-status-bulk",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerStatusBulk
    },
    {
      path: "/reporting/containers/compact",
      name: "reporting-containers-compact",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerCompact
    },
    {
      path: "/reporting/containers-all-in-one",
      name: "reporting-containers-all-in-one",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerAllInOne
    },
    {
      path: "/reporting/containers-mobile",
      name: "reporting-containers-mobile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerMobile
    },
    {
      path: "/reporting/containers-history",
      // name: "containers-list",
      name: "reporting-containers-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerHistory
    },
    {
      path: "/reporting/containers-history/:id",
      name: "reporting-containers-history-id",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerHistoryDetail
    },
    {
      path: "/reporting/containers-usage",
      name: "reporting-containers-usage",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerUsage
    },
    {
      path: "/logs/injection",
      name: "logs-injection",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: LogInjection
    },
    {
      path: "/logs/request",
      name: "logs-request",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: LogRequest
    },
    {
      path: "/areas",
      name: "areas",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataArea
    },
    {
      path: '/areas/:id',
      name: "area-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataAreaDetail,
    },
    {
      path: "/containers",
      name: "containers",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataContainer
    },
    {
      path: "/containers/:id",
      name: "containers-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataContainerDetail
    },
    {
      path: "/containers/add",
      name: "containers-add",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataContainerAdd
    },
    {
      path: '/reporting/containers-status/:id',
      name: "reporting-containers-status-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ReportingContainerStatusDetail,
    },
    {
      path: "/groups",
      name: "groups",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataGroup
    },
    {
      path: "/groups/:id",
      name: "group-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataGroupDetail
    },
    {
      path: "/offices",
      name: "offices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataOffice
    },
    {
      path: '/offices/:id',
      name: "office-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataOfficeDetail,
    },
    {
      path: "/districts",
      name: "districts",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDistrict
    },
    {
      path: '/districts/:id',
      name: "district-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDistrictDetail,
    },
    {
      path: "/manufacturers",
      name: "manufacturers",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataManufacturer
    },
    {
      path: '/manufacturers/:id',
      name: "manufacturer-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataManufacturerDetail,

    },
    {
      path: "/containerTypes",
      name: "containerTypes",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataContainerType
    },
    {
      path: '/containerTypes/:id',
      name: "containerType-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataContainerTypeDetail,
    },
    {
      path: "/divisions",
      name: "divisions",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDivision
    },
    {
      path: '/divisions/:id',
      name: "division-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDivisionDetail,
    },
    {
      path: "/devicetypes",
      name: "devicetypes",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDeviceType
    },
    {
      path: '/devicetypes/:id',
      name: "deviceType-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDeviceTypeDetail,
    },
    {
      path: "/deviceStatuses",
      name: "deviceStatuses",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDeviceStatus
    },
    {
      path: '/deviceStatuses/:id',
      name: "deviceStatus-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataDeviceStatusDetail,
    },
    {
      path: "/operators",
      name: "operators",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataOperator
    },
    {
      path: '/operators/:id',
      name: "operator-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataOperatorDetail,
    },
    {
      path: '/property-list',
      name: "property-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataPropertyList,
    },
    {
      path: '/property-list/:id',
      name: "property-list-details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataPropertyListDetail,
    },
    {
      path: '/sites/admin',
      name: "sites-admin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataSiteAdmin,
    },
    {
      path: '/sites/otodata',
      name: "sites-otodata",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      // component: MasterDataSiteAddOtodata,
      component: MasterDataSiteAdmin,
    },
    {
      path: '/sites/decommission',
      name: "sites-decom",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataSiteDecomm,
    },
    // {
    //   path: "/permareas",
    //   name: "perm-areas",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: MasterDataPermissionArea,
    // },
    // {
    //   path: '/permareas/:id',
    //   name: "perm-areas-details",
    //   meta: {
    //     requiresAuth: true,
    //     layout: defaultLayout
    //   },
    //   component: MasterDataPermissionAreaDetail,
    // },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      // component: loadView("login-form")
      component: Login
    },
    {
      path: "/request-password",
      name: "request-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password"
      },
      component: RequestPassword
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password"
      },
      component: ResetPassword
    },
    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataUser
    },
    {
      path: '/users/:id',
      name: "user-single",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataUserDetail,
    },
    {
      path: '/users/member/:id',
      name: "user-group-membership",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MasterDataUserGroupMembership,
    },
    
  ],
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "reporting-containers-status" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) { //requires auth
    if (!auth.loggedIn()) { //requires auth and user NOT logged in
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else { //requires auth and user is logged in
      next();
    }
  } else { //doesnt require auth
    next();
  }
});

export default router;

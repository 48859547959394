export  default {
    getProfile(state) {
        return state.profile;
    },
    getUsers(state) {
        return state.users;
    },
    getUser(state) {
        return state.user;
    },
    getUserGroups(state) {
        return state.userGroups;
    },

};
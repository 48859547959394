<template>
  <h2 class="content-block">Site Administration</h2>

  <DxToolbar class="menubar">
    <DxItem
        :options="cancelButtonOptions"
        location="before"
        widget="dxButton"
      />
  </DxToolbar>

  <DxTabPanel
    :height="100"
    :data-source="tabMenuItems"
    v-model:selected-index="selectedIndex"
    :loop="false"
    :animation-enabled="true"
    :swipe-enabled="false"
  >
    <template #title="{ data: menu }">
      <span>{{ menu.Action }}</span>
    </template>
  </DxTabPanel>

  <NewSite v-if="(selectedIndex==0)"></NewSite>
  <TransferSite v-else-if="(selectedIndex==1)"></TransferSite>
  <DecommissionSite v-else-if="(selectedIndex==2)"></DecommissionSite>

</template>

<script>

import DxTabPanel from 'devextreme-vue/tab-panel';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DecommissionSite from "./site_decommission.vue";
import NewSite from "./site_new_otodata.vue";
import TransferSite from "./site_transfer.vue";

export default {
  components: {
    DxTabPanel,
    DxToolbar,
    DxItem,
    NewSite,
    DecommissionSite,
    TransferSite
  },
  data() {
    return { 
      cancelButtonOptions: {
        icon: 'revert',
        onClick: () => {
          this.$router.go(-1);
        },
      },
      selectedIndex: 1,
      tabMenuItems : [{
        ID: 1,
        Action: "Add New Site",
      }, {
        ID: 2,
        Action: "Transfer Site",
      }, {
        ID: 3,
        Action: "Decommission SIte",
      }], 
    };
  },
  created() {
    // this.loadPageData();
  },
  computed: {
  },
  methods: {
    async loadPageData(refresh = false) {
      try {
        //do something
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
.fld{
  margin: 20px 0 0 2px;
}
.btn{
  margin: 50px 0 0 2px;
}
.menubar{
  padding: 0 60px 0 60px ;
}
h6 {
  margin: 0 0 0 0;
  font-size: 14px;
  font-weight: lighter;
  /* text-align: right; */
}
</style>


export default {

    getPermContainerGroups(state) {
        return state.permContainerGroups;
    },
    getCurrentPermContainerGroup(state) {
        return state.currentPerm;
    },
    getContainerOperAreas(state, payload) {
        return state.containerOperAreas;
    },

};